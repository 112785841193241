:root {
  --bg: black;
  --color:gold;
  --dark_color:#b39800;
	--light_color:yellow;
  --body_color: green;
  --slot_canvas_color: #fff7cc;

  --red: red;
  --darkred: darkred;
  --green: green;
  --darkgreen: darkgreen;
  --green_transparent_3: rgba(0, 128, 0, 0.3);
  --red_transparent_3 : rgba(255, 0, 0, 0.3);
  --orange: orange;  

  --padding: 10px;
  --padding_small: 5px;
  --padding_big: 20px;
  --radius:10px;
  --radius_small:5px;
  --radius_big:25px;

  --transparent_2: rgba(0, 0, 0, 0.2);
	--transparent_5: rgba(0, 0, 0, 0.5);
	--transparent_8: rgba(0, 0, 0, 0.8);
  --transparent_color: rgba(255, 215, 0, 0.2);

  --firework_color: red;
}

body{	
	color:var(--color);
	font-family: 'Roboto Condensed';
  font-size: 14px;
  line-height: 16px;
  text-align:center;
}
body{
  background-color:var(--body_color);
}
.grad:before{
	content: "";
  background-image: url('./../img/bg.jpg');
	background-image: url('./../img/noise.png'), url('./../img/bg001.jpg');
	background-repeat: repeat, repeat;
	background-size: auto, 200px auto;
	position:absolute;
	z-index: 1;
	top:0px;
	bottom:0px;
	left:0px;
	right:0px;
	width:100%;
  opacity: 0.4;
}
.grad:after{
	content: "";
	background-color:transparent;
	background-image: radial-gradient(rgba(0,0,0,0.2), black);
	position:absolute;
	z-index: 1;
	top:0px;
	bottom:0px;
	left:0px;
	right:0px;
	width:100%;
}
#casino_root{
  position: fixed;
  z-index: 9999;
  top: 0px;
  bottom: 0px;
  width: 100%;
  left: 0px;
  right: 0px;
}

.deco {
	--c:var(--color);   /* color */
	--b:1px;   /* thickness of lines */
	--o1:15px; /* offest of lines*/
	--o2:10px; /* offset of rectangle */
	
	border:var(--o1) solid transparent; 
	outline:var(--b) solid var(--c); 
	outline-offset:calc(-1*var(--o2));
	background:
	  linear-gradient(var(--c) 0 0) top,
	  linear-gradient(var(--c) 0 0) left,
	  linear-gradient(var(--c) 0 0) bottom,
	  linear-gradient(var(--c) 0 0) right;
	background-size:200% var(--b),var(--b) 200%; 
	background-origin:padding-box;
	background-repeat:no-repeat;
	padding:1px;
}

* {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  box-sizing: border-box;
}
.selectable {
  -webkit-touch-callout: text; /* iOS Safari */
    -webkit-user-select: text; /* Safari */
     -khtml-user-select: text; /* Konqueror HTML */
       -moz-user-select: text; /* Old versions of Firefox */
        -ms-user-select: text; /* Internet Explorer/Edge */
            user-select: text; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p, address {
    margin: 0px;
}
h1 {
	font-size: 50px;
	line-height: 54px;
  font-family: 'Grenadier';
}
h2 {
	font-size: 30px;
	line-height: 34px;
  font-family: 'Grenadier';
}
h3 {
	font-size: 20px;
	line-height: 24px;
}
h4 {
	font-size: 16px;
	line-height: 18px;
}
h5 {
	font-size: 14px;
	line-height: 16px;
}
h6 {
  font-size: 12px;
  line-height: 14px;
}

ul{
	margin:0px;
	padding:0px;
}
ul li{
	list-style: none;
  display: block;
}
ul.list_circle li{
	list-style-type: circle;
}

a, a:hover, a:focus, a:active{
	color:var(--yellow);
	text-decoration: none;
}

.text_red{
  color: var(--red);
}
.text_green{
  color: var(--green);
}
.capitalize{
  text-transform: capitalize;
}
.uppercase{
  text-transform: uppercase;
}
.text_center{
  text-align: center;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#logo_icon{
  width: 40px;
}
#logo_splash{
  width: 60px;
}

.grey_image{
	filter: grayscale(100%);
}

textarea.shadow_concav, input.shadow_concav, button.shadow_concav, .shadow_concav{
	-moz-box-shadow:    inset 4px 4px 8px 2px var(--transparent_8)!important;
  -webkit-box-shadow: inset 4px 4px 8px 2px var(--transparent_8)!important;
  box-shadow:         inset 4px 4px 8px 2px var(--transparent_8)!important;
}
textarea.shadow_convex, input.shadow_convex, button.shadow_convex, .shadow_convex{
	-moz-box-shadow:    4px 4px 8px 2px var(--transparent_8)!important;
  -webkit-box-shadow: 4px 4px 8px 2px var(--transparent_8)!important;
  box-shadow:         4px 4px 8px 2px var(--transparent_8)!important;
}

textarea:focus, input:focus{
  outline: none;
}

.transparent_text svg text{
  font-family: 'Grenadier';
}

/* FORMS, BUTTONS, INPUTS, TEXTAREA */
.sign_container{
  width: 100%;
  max-width: 480px;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  padding: var(--padding);
  font-size: 14px;
  line-height: 14px;
}
.sign_container_box{
  background-color: var(--transparent_5);
  padding: var(--padding);
  position: relative;
  z-index: 1;
}
.sign_box{
  color: var(--color);
  padding: var(--padding);
  border-radius: var(--radius);
  text-align: center;
  height: 250px;
  position: relative;
  z-index: 1;
}
.sign_box.isMinor_sign{
  padding: 50px;
}
.sign_box.isMinor_sign p{
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0%;
  width: 100%;
  transform: translate(0px, -50%);
}
.sign_up_info{
  margin-bottom: var(--padding);
}
.sign_container input{
  margin-bottom: var(--padding_small);
  width: 100%;
  max-width: 205px;
}
.sign_container ul{
  margin-bottom: var(--padding_big);
}
.sign_container ul li{
  padding: var(--padding_small);
  border: solid 1px var(--color);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 14px;
  width: 150px;
  cursor: pointer;
}
.sign_container ul li.active, .sign_container ul li:hover, .sign_container ul li:focus, .sign_container ul li:active{
  background-color: var(--transparent_color);
}
.sign_container #signin_tab{
  border-radius: var(--radius) 0px 0px var(--radius);
}
.sign_container #signup_tab{
  border-radius: 0px var(--radius) var(--radius) 0px;
}
.sign_container button{
  margin-top: var(--padding);
  border-radius: var(--padding);
  height: 25px;
  width: 100%;
  max-width: 150px;
  font-size: 14px;
  line-height: 26px;
}
.sign_container form{
  margin-top: var(--padding);
}
.sign_container .label_container{
  text-align: right;
  padding-top: var(--padding_small);
}
.sign_container .input_container{
  text-align: left;
  position: relative;
  z-index: 1;
}
.sign_container .input_container .input_eye{
  position: absolute;
  z-index: 1;
  top: 6px;
  right: 65px;
  cursor: pointer;
}
.sign_container_error{
  padding: var(--padding);
}
.sign_extra_info{
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
  padding: var(--padding_small);
  margin-top: var(--padding);
  height: 40px;
}
.sign_extra_info:hover p{
  text-decoration: underline;
}
.sign_footer{
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  padding: var(--padding);
}
.sign_problem{
  padding: var(--padding);
  cursor: pointer;
}
.sign_problem:hover h6{
  text-decoration: underline;
}
.signProblem_box{
  margin-bottom: var(--padding);
}
.signProblem_box *{
  text-decoration: none!important;
}

/* checkbox and radio buttons */
.checkbox_radio_container input[type="radio"], .checkbox_radio_container input[type="checkbox"] {
	-webkit-appearance: none;
	appearance: none;	/* For iOS < 15 to remove gradient background */
	background-color: transparent;
	appearance: none;
  padding: 0px !important;
  width: 20px;
  height: 20px;
	border-radius: 100%!important;
	display: grid;
  place-content: center;
  cursor: pointer;
  margin: 0px 0px 0px 0px !important;
  border: solid 1px var(--color);
}
.checkbox_radio_container input[type="radio"]{
	border-radius: 100%!important;
}
.checkbox_radio_container input[type="checkbox"] {
	border-radius: var(--radius_small)!important;
}

.checkbox_radio_container input[type="radio"]::before{
	content: "";
	width: 10px;
	height: 10px;
	border-radius: 100%;
	transform: scale(0);
	transition: 120ms transform ease-in-out;
	box-shadow: inset 1em 1em var(--color);
	background-color: CanvasText;
}
.checkbox_radio_container input[type="checkbox"]::before {
	content: "";
	width: 10px;
	height: 10px;
	border-radius: 100%;
	transform: scale(0);
	transition: 120ms transform ease-in-out;
	box-shadow: inset 1em 1em var(--color);
	background-color: CanvasText;
	transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
.checkbox_radio_container input[type="radio"]:checked::before, .checkbox_radio_container input[type="checkbox"]:checked::before {
	transform: scale(1);
}
.checkbox_radio_container input[type="radio"]:focus, .checkbox_radio_container input[type="checkbox"]:focus {
	outline: max(2px, 0.15em) solid var(--color);
}
.checkbox_radio_container label{
	display: inline-flex;
  align-items: center;
  gap: 10px;
	margin:0px var(--padding_small)!important;
}
.checkbox_radio_container label h6{
  text-align: left;
}
.checkbox_radio_container h6{
  margin-top: 4px;
}
.checkbox_radio_container label svg{
  font-size: 40px;
}
.checkbox_radio_container.payment_details_title label{
  height: 24px;
  font-size: 20px;
  line-height: 20px;
}

/* BUTTONS */
.mybutton{
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  padding: 0px var(--padding);
  border-radius: var(--padding_big);
  height: 30px;
  width: 100%;
  cursor: pointer;
}
.mybutton svg{
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  line-height: 18px;
}
.round{
  width: 50px;
  height: 50px;
  background-color: var(--color)!important;
  border: solid 1px var(--color)!important;
  color: var(--bg)!important;
  border-radius: 100%;
  padding: var(--padding_small);
}
.round:hover, .round:active, .round:focus{
  background-color: var(--bg)!important;
  color: var(--color)!important;
}
.button_transparent{
  background-color: transparent!important;
  border: solid 1px var(--color)!important;
  color: var(--color)!important;
}
.button_transparent:hover, .button_transparent:active, .button_transparent:focus{
  background-color: var(--transparent_color)!important;
}
.button_fullcolor, .button_fullcolor:active, .button_fullcolor:focus{
  background-color: var(--color)!important;
  border: solid 1px var(--color)!important;
  color: var(--bg)!important;
}
.button_fullcolor:hover{
  background-color: transparent!important;
  color: var(--color)!important;
}
.button_fullcolor_dark{
  background-color: var(--bg)!important;
  border: solid 1px var(--bg)!important;
  color: var(--color)!important;
}
.button_fullcolor_dark:hover, .button_fullcolor_dark:active, .button_fullcolor_dark:focus{
  background-color: var(--color)!important;
  color: var(--bg)!important;
}

.button_action_group{
  padding: var(--padding);
}
.button_action_group button{
  margin-bottom: var(--padding);
}
.button_action_group button:last-child{
  margin-bottom: 0px;
}
.button_action_group .round{
  margin: 0px 5px!important;
}

.close{
	width: 30px;
  height: 30px;
  background-color: var(--color);
  border: solid 1px var(--color);
  color: var(--bg);
  border-radius: 100%;
  position: absolute;
  z-index: 1;
  top: -15px;
  right: -15px;
  font-size: 30px;
  line-height: 25px;
  font-family: Helvetica, sans-serif;
  font-weight: bold;
  cursor: pointer;
}
.close:active, .close:focus, .close:hover{
  background-color: var(--bg);
  color: var(--text);
}

input, textarea{
  border-radius: var(--radius);
  padding: var(--padding_small) var(--padding);
  font-size: 14px;
  line-height: 14px;
  height: 25px;
  width: 100%;
  background-color: transparent;
}
.input_light{
  border: solid 1px var(--color);
  color: var(--color);
}
.input_dark{
  border: solid 1px var(--bg);
  color: var(--bg);
}
textarea {
	resize: none;
  min-height: 175px;
}

.alert {
  width: 100%;
  padding: var(--padding);
  border-radius: var(--radius);
  font-size: 12px;
  line-height: 12px;
  margin: 0px;
  margin-top: var(--padding);
}
.alert.alert-success {
  background-color: var(--green_transparent_3);
  border: solid 1px var(--green);
  color: var(--green);
}
.alert.alert-danger {
  background-color: var(--red_transparent_3);
  border: solid 1px var(--red);
  color: var(--red);
}
.modal-body .alert{
  margin-top: 0px;
  margin-bottom: var(--padding);
}
.forgotPassword .alert{
  margin-bottom: 0px;
  margin-top: var(--padding_small);
}

.page_exit{
  text-align: center;
  padding: var(--padding);
}
#exit_salon{
  display: inline-block;
  max-width: 120px;
}

/* SALON */
.header{
  text-align: center;
  margin-bottom: var(--padding);
}
.header_container{
  position: relative;
  z-index: 1;
}
#header_sign{
  margin: var(--padding);
}

#header_salon #logo_icon{
  width: 30px;
}
#header_salon h1{
  font-size: 40px;
  line-height: 40px;
}

.cell_salon_container, .cell_market_container{
  padding: var(--padding);
  text-align: center;
}
.cell_salon, .cell_market, .cell_howToPlay{
  border: solid 1px var(--color);
  border-radius: var(--radius);
  padding: var(--padding);
  height: 120px;
  background-color: var(--transparent_2);
}
.cell_salon, .cell_info, .cell_market{
  margin-bottom: var(--padding);
}
.cell_howToPlay{
  margin-bottom: 20px
}
.cell_salon h4, .cell_market h4{
  font-size: 20px;
  line-height: 20px;
}
.cell_market h4{
  margin-bottom: var(--padding_small);
}

.casino_games_container{
  overflow: hidden;
}
.casino_games_table_container{
  overflow: hidden;
  height: 0px;
  -webkit-transition: height 1s ease;
    -moz-transition: height 1s ease;
      -o-transition: height 1s ease;
     -ms-transition: height 1s ease;
         transition: height 1s ease;
}
.casino_games_table_container.open{
  height: 140px;
}
.casino_games_title{
  position: relative;
  z-index: 1;
  background-color: transparent;
  padding: var(--padding);
  margin-bottom: var(--padding_small);
  border-radius: var(--radius_big);
  text-align: center;
  border: solid 1px var(--color);
  cursor: pointer;
}
.casino_games_title:hover{
    background-color: var(--transparent_color);
}
.casino_games_title .casino_games_title_arrow{
  position: absolute;
  z-index: 1;
  top: 12px;
  right: 20px;
}

/* dropdown */
.dropdown{
  border-radius: var(--radius);
}
.dropdown .dropdown-toggle, .dropdown .dropdown-toggle:hover, .dropdown .dropdown-toggle:active, .dropdown .dropdown-toggle:focus{
  background-color: transparent;
  border: solid 1px var(--color);
  color: var(--color);
  box-shadow: none!important;
  border-radius: var(--radius);
}
.dropdown-toggle::after{
  display: none;
}
.dropdown .dropdown-menu{
  z-index: 9999;
  background-color: var(--bg);
  border: solid 1px var(--color);
  color: var(--color);
  width: 100%;
  border-radius: var(--radius);
  max-height: 150px;
  overflow-x: hidden;
	overflow-y: auto;
  padding: var(--padding_small);
}
.salon_games_dropdown .dropdown .dropdown-menu{
  max-height: 170px;
}
.dropdown-item{
  color: var(--color);
  text-align: center;
  padding: var(--padding_small) var(--padding);
}
.dropdown-item:focus, .dropdown-item:active, .dropdown-item:hover{
  background-color: var(--color);
  color: var(--bg);
}
.settings_theme .dropdown-item:focus, .settings_theme .dropdown-item:active, .settings_theme .dropdown-item:hover{
  background-color: transparent;
}
.salon_games{
  position: relative;
  z-index: 1;
}
.salon_games_dropdown .dropdown{
  border-radius: var(--radius_big);
}
.salon_games .dropdown{
  z-index: 9999;
}
.salon_games .dropdown .dropdown-toggle{
  width: 100%;
  border-radius: var(--radius_big);
  background-color: var(--transparent_2);
}
.language .dropdown .dropdown-menu{
  min-width: auto;
  max-height: 100px;
}
.language .dropdown .dropdown-toggle{
  font-size: 12px;
  line-height: 12px;
  width: 100%;
}
.language .dropdown-item{
  padding: var(--padding_small);
  font-size: 12px;
  line-height: 12px;
}

/* POPUP */
.mymodal{
  z-index: 9999;
}
.modal-dialog{
  padding: 0px var(--padding);
}
.modal-body{
  padding: var(--padding_big);
}
.modal-content{
  background-color: var(--color);
  border: solid 1px var(--bg);
  color: var(--bg);
}
.modal-header{
  padding: var(--padding_small) var(--padding);
  background: var(--bg);
  color: var(--color);
  border: solid 1px var(--color);
}
.modal-title img{
  width: 40px;
}
.closeButton{
  background: var(--color);
  border: solid 1px var(--bg);
  color: var(--bg);
  border-radius: 100%;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  z-index: 1;
  top: -10px;
  right: -10px;
  cursor: pointer;
}
.closeButton span{
  font-size: 14px;
  line-height: 25px;
  font-weight: bold;
}
.closeButton:hover{
  background: var(--bg);
  color: var(--color);
  border: solid 1px var(--color);
}
.popup_body{
  color: var(--bg);
}
.isMinor_button{
  display: inline-block;
  width: 50%;
  padding: var(--padding_small);
}
.mymodal .modal-header{
  display: flex;
  justify-content: center;
  align-items: center;
}
.mymodal .modal-header .modal-title{
  font-size: 20px;
  line-height: 28px;
}
.modal-content input{
  border: solid 1px var(--bg);
  color: var(--bg);
}

#myModal_gift .modal-content{
  background-color: transparent;
  border: solid 1px transparent;
}
#myModal_gift .popup_body{
  color: transparent;
}

#welcome_popup .box_container{
  width: 400px;
  margin: -200px auto 0px auto;
}
#welcome_popup .box {
  position: relative;
}
#welcome_popup .box::before {
  content: "";
  width: 400px;
  height: 400px;
  background-color: var(--color);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  border-radius: 50%;
}
#welcome_popup .box_body {
  position: relative;
  height: 180px;
  width: 200px;
  top: 180px;
  left: 100px;
  background-color: var(--red);
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
  box-shadow: 0px 4px 8px 0px var(--transparent_5);
  background: linear-gradient(var(--darkred), var(--red));
  animation-play-state: paused;
}
#welcome_popup .box_body .img{
  opacity: 0;
  transform: translateY(0%);
  transition: all 1.3s;
  margin: 0 auto;
  display: block;
}
#welcome_popup .box_body .gift_text span{
  display: block;
  color: var(--bg);
}
#welcome_popup .box_body .gift_text span:first-child{
  font-size: 32px;
  line-height: 32px;
  font-weight: bold;
}
#welcome_popup .box_body .gift_text span:last-child{
  font-size: 24px;
  line-height: 24px;
}
#welcome_popup .box_body.active {
  cursor: pointer;
  -webkit-animation: box-body 1s forwards ease-in-out;
  animation: box-body 1s forwards ease-in-out;
}
#welcome_popup .box_body.active .img{
  opacity: 1;
  z-index: 0;
  transform: translateY(-100px);
}
#welcome_popup .box_body.active .box_lid {
  -webkit-animation: box-lid 1s forwards ease-in-out;
  animation: box-lid 1s forwards ease-in-out;
}
#welcome_popup .box_body.active .box-bowtie::before {
  -webkit-animation: box-bowtie-left 1.1s forwards ease-in-out;
  animation: box-bowtie-left 1.1s forwards ease-in-out;
}
#welcome_popup .box_body:active .box-bowtie::after {
  -webkit-animation: box-bowtie-right 1.1s forwards ease-in-out;
  animation: box-bowtie-right 1.1s forwards ease-in-out;
}
#welcome_popup .box_body::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 50px;
  background: white
}
#welcome_popup .box_lid {
  position: absolute;
  z-index: 1;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 90%;
  background-color: var(--red);
  height: 40px;
  width: 220px;
  border-radius: 5%;
  box-shadow: 0 8px 4px -4px var(--transparent_5);
}
#welcome_popup .box_lid::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 50px;
  background: white
}
#welcome_popup .box_bowtie {
  z-index: 1;
  height: 100%;
}
#welcome_popup .box_bowtie::before, #welcome_popup .box_bowtie::after {
  content: "";
  width: 83.3333333333px;
  height: 83.3333333333px;
  border: 16.6666666667px solid white;
  border-radius: 50% 50% 0 50%;
  position: absolute;
  bottom: 99%;
  z-index: -1;
}
#welcome_popup .box_bowtie::before {
  left: 50%;
  -webkit-transform: translateX(-100%) skew(10deg, 10deg);
  transform: translateX(-100%) skew(10deg, 10deg);
}
#welcome_popup .box_bowtie::after {
  left: 50%;
  -webkit-transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
  transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
}

@-webkit-keyframes box-lid {
  0%, 42% {
    -webkit-transform: translate3d(-50%, 0%, 0) rotate(0deg);
    transform: translate3d(-50%, 0%, 0) rotate(0deg);
  }
  60% {
    -webkit-transform: translate3d(-85%, -230%, 0) rotate(-25deg);
    transform: translate3d(-85%, -230%, 0) rotate(-25deg);
}
  90%, 100% {
    -webkit-transform: translate3d(-119%, 225%, 0) rotate(-70deg);
    transform: translate3d(-119%, 225%, 0) rotate(-70deg);
  }
}
@keyframes box-lid {
  0%, 42% {
    -webkit-transform: translate3d(-50%, 0%, 0) rotate(0deg);
    transform: translate3d(-50%, 0%, 0) rotate(0deg);
  }
  60% {
    -webkit-transform: translate3d(-85%, -230%, 0) rotate(-25deg);
    transform: translate3d(-85%, -230%, 0) rotate(-25deg);
  }
  90%, 100% {
    -webkit-transform: translate3d(-119%, 225%, 0) rotate(-70deg);
    transform: translate3d(-119%, 225%, 0) rotate(-70deg);
  }
}
@-webkit-keyframes box-body {
  0% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
  25% {
    -webkit-transform: translate3d(0%, 25%, 0) rotate(20deg);
    transform: translate3d(0%, 25%, 0) rotate(20deg);
  }
  50% {
    -webkit-transform: translate3d(0%, -15%, 0) rotate(0deg);
    transform: translate3d(0%, -15%, 0) rotate(0deg);
  }
  70% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
}
@keyframes box-body {
  0% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
  25% {
    -webkit-transform: translate3d(0%, 25%, 0) rotate(20deg);
      transform: translate3d(0%, 25%, 0) rotate(20deg);
  }
  50% {
    -webkit-transform: translate3d(0%, -15%, 0) rotate(0deg);
    transform: translate3d(0%, -15%, 0) rotate(0deg);
  }
  70% {
    -webkit-transform: translate3d(0%, 0%, 0) rotate(0deg);
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
}
@-webkit-keyframes box-bowtie-right {
  0%, 50%, 75% {
    -webkit-transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
    transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
  }
  90%, 100% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.3);
  }
}
@keyframes box-bowtie-right {
  0%, 50%, 75% {
    -webkit-transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
    transform: translateX(0%) rotate(90deg) skew(10deg, 10deg);
  }
  90%, 100% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.3);
  }
}
@-webkit-keyframes box-bowtie-left {
  0% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
    transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }
  50%, 75% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
  }
  90%, 100% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
    transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }
}
@keyframes box-bowtie-left {
  0% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
    transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }
  50%, 75% {
    -webkit-transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
    transform: translate(-50%, -15%) rotate(45deg) skew(10deg, 10deg);
  }
  90%, 100% {
    -webkit-transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
    transform: translateX(-100%) rotate(0deg) skew(10deg, 10deg);
  }
}

@media (max-width: 767px) {
  #welcome_popup .box_container{
    width: 200px;
    margin: -100px auto 0px auto;
  }
  #welcome_popup .box::before {
    width: 220px;
    height: 220px;
  }
  #welcome_popup .box_body {
    height: 80px;
    width: 100px;
    top: 110px;
    left: 60px;
  }
  #welcome_popup .box_lid {
    bottom: 90%;
    height: 20px;
    width: 110px;
  }
  #welcome_popup .box_bowtie::before, #welcome_popup .box_bowtie::after {
    width: 40px;
    height: 40px;
    border: 8px solid white;
    bottom: 99%;
  }
  #welcome_popup .box_body::after {
    width: 25px;
  }
  #welcome_popup .box_lid::after {
    width: 25px;
  }
  #welcome_popup .box_body:active .img{
    transform: translateY(-65px);
  }
  #welcome_popup .box_body .gift_text span:first-child{
    font-size: 20px;
    line-height: 20px;
  }
  #welcome_popup .box_body .gift_text span:last-child{
    font-size: 14px;
    line-height: 14px;
  }
}

.changeUsername_box, .changePassword_box{
  margin-bottom: var(--padding_small);
}

.error .modal-content{
  background-color: var(--red);
}
.error .modal-header{
  background: var(--darkred); 
  border: solid 1px var(--red);
  color: var(--bg);
}
.error .closeButton{
  background: var(--red);
  border: solid 1px var(--bg);
  color: var(--bg);
}
.error .closeButton:hover{
  background: var(--darkred);
  border: solid 1px var(--red);
  color: var(--bg);
}
.success .modal-content{
  background-color: var(--green);
}
.success .modal-header{
  background: var(--darkgreen); 
  border: solid 1px var(--green);
  color: var(--bg);
}
.success .closeButton{
  background: var(--green);
  border: solid 1px var(--bg);
  color: var(--bg);
}
.success .closeButton:hover{
  background: var(--darkgreen);
  border: solid 1px var(--green);
  color: var(--bg);
}

.areYouSure_buttons{
  margin-top: var(--padding_small);
}
.areYouSure_buttons .areYouSure_button{
  display: inline-block;
  width: 100%;
  max-width: 100px;
  padding: 0px var(--padding_small);
}
.areYouSure_buttons .areYouSure_button .button_fullcolor{
  background-color: var(--bg)!important;
  border: solid 1px var(--bg)!important;
  color: var(--color)!important;
}
.areYouSure_buttons .areYouSure_button .button_fullcolor:hover{
  background-color: var(--dark_color)!important;
  border: solid 1px var(--dark_color)!important;
}

/* COOKIES */
.cookies_msg_container {
  position: fixed;
  z-index: 99999;
  width: 100%;	
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;	
  background-color: var(--transparent_5);  
}
.cookies_box{
  position: absolute;
  z-index: 99999;
  bottom: 50%;
  left: 0px;
  right: 0px;
  width: 100%;
  padding: var(--padding_big);
  text-align: center;
}
.cookies_msg{
  display: inline-block;
  width: 100%;
  max-width: 600px;
  background: var(--color);	
  border-radius: var(--radius);
  padding: var(--padding_big);
  border: solid 1px var(--color);
  color:var(--bg);
  text-align: left;  
}
.cookies_text {
  display: inline-block;
  vertical-align: top;
  width: 75%;  
  font-size: 14px;
}
.confirm_cookies {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  position: relative;
  text-align: center;
}
.cookies_msg h4{
  font-size: 18px;
  line-height: 20px;
  margin-bottom: var(--padding_small);
}
.cookies_msg p{
  font-size: 14px;
  line-height: 16px;
}
.confirm_cookies button {
  width: 100%;
  max-width: 100px;
  background:var(--color);
  color:var(--bg);
  border-radius:var(--padding_big);
  border:solid 1px var(--color);
}
.confirm_cookies button:hover {
  background:transparent;
}

/* LANGUAGE */
.language{
  position: absolute;
  z-index: 2;
  top:10px;
  left: 10px;
  padding: var(--padding_small);
  font-size: 12px;
  line-height: 12px;
  width: 100%;
  max-width: 90px;
}
.language li{
  display: inline-block;
  vertical-align: middle;
  padding: 0px var(--padding_small);
  border-right: solid 1px var(--color);
  width: 45px;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  cursor: pointer;
}
.language li:last-child{
  border: solid 0px transparent;
}

/* FOOTER */
#page-container {
  position: relative;
  min-height: 100vh;
}
.content_wrap{
  padding: var(--padding_big) 40px;
  transform: translate(0px, -50%);
  position: absolute;
  width: 100%;
  top: 50%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.content_wrap .title{
  text-align: center;
  padding-bottom: var(--padding);
  margin-bottom: var(--padding);
  border-bottom: solid 1px var(--color);
  font-family: 'Grenadier';
}
#panel_user_market .title{
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: none;
}
.footer_container {
  position: fixed;
  z-index: 9999;
  bottom: -1px;
  width: 100%;
}
.footer_button_container{
  text-align: right;
  padding: 0px var(--padding);
  font-size: 12px;
  line-height: 12px;
  cursor: pointer;
}
.footer_button{
  background-color: var(--bg);
  border:solid 1px var(--color);
  border-bottom: 0 solid transparent;
  color: var(--color);
  padding: var(--padding) var(--padding) var(--padding_small) var(--padding);
  border-radius: var(--radius) var(--radius) 0px 0px;
  display: inline-block;
  position: relative;
  top: 2px;
}
.footer_body{
  background-color: var(--bg);
  color: var(--color);
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  height: 1px;
  overflow: hidden;
  transition: height 0.2s ease-out;
}
.footer_body h6{
  font-size: 11px;
}
.footer_container.up .footer_body{
  border-top:solid 1px var(--color);
  height: 160px;
}
.footer_list{
  padding: var(--padding) 0px;
  text-align: left;
}
.footer_list h4{
  border-bottom:solid 1px var(--color);
  padding-bottom: var(--padding_small);
  margin-bottom: var(--padding_small);
}
.footer_list li{
  cursor: pointer;
  line-height: 20px;
}
.newsletter_container{
  padding: var(--padding) 0px;
  text-align: left;
}
.newsletter_container h4{
  border-bottom:solid 1px var(--color);
  padding-bottom: var(--padding_small);
  margin-bottom: var(--padding_small);
}
.newsletter_box input, .newsletter_box button{
  display: inline-block;
  vertical-align: top;
  height: 25px;
}
.newsletter_box input{
  border-radius: var(--radius) 0px 0px var(--radius);
  width: 80%;
}
.newsletter_box button{
  border-radius: 0px var(--radius) var(--radius) 0px;
  width: 20%;
}
.newsletter_box button svg{
  font-size: 14px;
}
footer{
  border-top:solid 1px var(--color);
  padding: var(--padding_small) 0px;
}
#ukraine_icon{
  width: 20px;
}

/* LOADER */
.page_content_loader{
  min-height: 300px;
}
.loader_container{
  position: fixed;
  z-index: 9999;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
}
.loader{
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translate(0%, -50%);
  font-size: 24px;
}
.loader_container .loader img{
	width:200px;
}

/* SALON SIDEBAR LEFT */
.salon_button_container{
	position: fixed;
  z-index: 1;
  top: 0px;
	bottom: 0px;
  left: 0px;
  width: 35px;
	-webkit-transition:0.7s;
  -moz-transition:0.7s;
	-o-transition: 0.7s;
	transition:0.7s;
}
.salon_button_box {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.salon_button{
  background-color: var(--transparent_5);
  border: 1px solid var(--color);
  border-left: none;
  color: var(--color);
  border-radius: 0px var(--radius) var(--radius) 0px;
  width: 35px;
  height: 145px;
  text-align: center;
  vertical-align: top;
  padding: var(--padding) var(--padding) var(--padding) var(--padding_small);
  cursor: pointer;
  margin-bottom: var(--padding);
}
.salon_button:focus, .salon_button:hover{
  background-color: var(--transparent_color);
}
.salon_button span{
  writing-mode: vertical-lr;
  text-orientation: upright;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  margin-top: var(--padding_small);
}

/* GAME */
.game_box{
  padding: var(--padding_big) 40px;
  transform: translate(0px, -50%);
  position: absolute;
  width: 100%;
  top: 50%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

/* GAME PANEL */
.panel_container{
	top:0px;
	bottom:0px;
	right:-365px;
	width:400px;
	position:fixed;	
	z-index:2;		
  -webkit-transition:0.7s;
  -moz-transition:0.7s;
  -o-transition: 0.7s;
	transition:0.7s;
}
.panel_button_box{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  left: 1px;
  width: 35px;
}
.panel_button{
  background-color: var(--bg);
  border: 1px solid var(--color);
  border-right: none;
  color: var(--color);
  border-radius: var(--radius) 0px 0px var(--radius);
  width: 35px;
  height: 130px;
  text-align: center;
  vertical-align: top;
  padding: var(--padding) var(--padding_small) var(--padding) var(--padding);
  cursor: pointer;
  margin-bottom: var(--padding_small);
}
.panel_button:focus, .panel_button:hover {
  background-color: var(--transparent_color);
}
.panel_container.open{	
	right:0px;
}
.panel_box{
	display:none;
	background-color: var(--transparent_5);
	background-image: linear-gradient(var(--bg), var(--transparent_5) 50%);
	border-left:solid 1px var(--color);
	padding: 20px var(--padding);	
	position:absolute;
	z-index:1;
	height:100%;
  top: 0px;
	right:0px;
	width:365px;
	text-align: left;
	color:var(--color);
}
.panel_box.active{
	display:block;
}
.panel_button span {
  writing-mode: vertical-lr;
  text-orientation: upright;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  margin-top: var(--padding_small);
}
#user_title{
  font-family: 'Grenadier';
  margin-bottom: var(--padding);
}
#user_title h3{
  font-size: 25px;
}
#user_subtitle{
	padding-left: var(--padding_small);
}
#user_tags, #user_list{
  margin-top: var(--padding);
}
.user_subtitle_left, .user_subtitle_right{
	display:inline-block;
	vertical-align: middle;
	width:50%;
}
.user_subtitle_right{
	text-align:right;
}
#user_subtitle i{
	display:inline-block;
	padding: 0px var(--padding_small);
}
#user_money, #user_streak{
	padding-left: var(--padding_small);
}
#user_panel_box .user_list{
	margin-top: var(--padding_small);
}
#user_panel_box .user_list li{
	border-bottom: solid 1px var(--yellow);
	padding: var(--padding);
	cursor: pointer;
}
.currency_img {
  width: 8px;
  margin: -4px 5px 0;
}
.cell_market .currency_img{
  display: inline-block;
  margin: 0px;
}
.user_list_button {
  display: inline-block;
  padding: var(--padding_small) var(--padding);
  border: solid 1px var(--color);
  width: 50%;
  cursor: pointer;
  text-align: center;
}
.user_list_button:first-child {
  border-radius: var(--radius) 0px 0px var(--radius);
}
.user_list_button:last-child {
  border-radius: 0px var(--radius) var(--radius) 0px;
}
.user_list_button.active, .user_list_button:hover{
  background: var(--transparent_color);
}
.user_list_button svg{
  margin-right: var(--padding_small);
}
#user_list li {
  border-bottom: solid 1px var(--color);
  padding: var(--padding);
  cursor: pointer;
}
#user_list li:last-child {
  border-bottom: solid 0px transparent;
}
#user_list li svg{
  margin-right: var(--padding_small);
}

#chatmessages{
  border-radius: var(--radius);
  margin-bottom: var(--padding);
  padding: var(--padding_small);
  height: 500px;
}

@media (max-width: 767px) and (orientation: landscape) {
  #chatmessages {
    height: 150px;
  }
  .chat_list{
    height: 75px;
  }
}
@media (max-width: 767px) and (orientation: portrait) {
  #chatmessages {
    height: 300px;
  }
}


#chatmessages .messages{
  overflow-x: hidden;
  overflow-y: auto;
}
#chatmessages .message{  
  margin-top: var(--padding_small);
}
#chatmessages .message_box .chat_header{  
  width: 33%;
  min-width: 100px;
}
#chatmessages .message_box .chat_header .user{
  border-radius: var(--radius_small) var(--radius_small) 0px 0px;
  background-color: var(--color);
  color: var(--bg);
  padding: var(--padding_small);
}
#chatmessages .message_box .chat_header .user p{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#chatmessages .message_box .chat_body{
  border-radius: 0px var(--radius_small) var(--radius_small) var(--radius_small);
  padding: var(--padding_small);
  border: solid 1px var(--color);
}
.chat_form input{
  margin-bottom: var(--padding);
}

.chat_list_container{
  padding: var(--padding_small);
}
.chat_list{
  padding: var(--padding_small);
  overflow: auto;
  max-height: 200px;
}
.chat_list li{
  margin-bottom: var(--padding_small);
  border-bottom: solid 1px var(--color);
}
.chat_list li .left{
	display:inline-block;
	vertical-align: middle;
	width:40%;
	padding: 5px;
	text-align:left;
}
.chat_list li .right{
	display:inline-block;
	vertical-align: middle;
	width:60%;
	padding: 5px;
	text-align:right;
}

/* TOOLTIP */
.my_tooltip {
	position: relative;
	display: inline-block;
	border-bottom: solid 1px var(--bg);
}
.my_tooltip .my_tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: var(--color);
	color: var(--bg);
	text-align: center;
  font-size: 12px;
  line-height: 14px;
  border-radius: var(--radius_small);
	padding: var(--padding_small);
	position: absolute;
	z-index: 1;
	top: 150%;
  left: -95px;
}
.my_tooltip .my_tooltiptext::after {
	content: "";
	position: absolute;
	bottom: 100%;
	right: 8px;
	border-width: var(--padding_small);
	border-style: solid;
	border-color: transparent transparent var(--color) transparent;
}
.my_tooltip:hover .my_tooltiptext {
	visibility: visible;
}
.my_tooltip svg{
  padding: 0 var(--padding_small);
}

/* TOOLTIP BUTTON */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  opacity: 1!important;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--color);
  color: var(--bg);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: auto;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  bottom: auto;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--color) transparent transparent transparent;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.tooltip.down .tooltiptext {
  top: 25px;
  bottom: auto;
}
.tooltip.down .tooltiptext::after {
  top: auto;
  bottom: 100%;
  border-color: transparent transparent var(--color) transparent;
}

/* GAMES */
.game_container{
  text-align: center;
  position: relative;
  min-height: 100vh;
}
.game_bets_container{
	position: fixed;
	z-index:9999;
	top:0px;
	bottom:0px;
	right:100%;
	width:100%;
	padding:0px 60px;
	-webkit-transition:0.7s;
  -moz-transition:0.7s;
	-o-transition: 0.7s;
	transition:0.7s;
}
.game_bets_container.open{
	right:0px;
}
.game_bets{
	position: relative;
	z-index:1;
	top:50%;
  left: 50%;
	width:100%;
	transform: translate(-50%, -50%);
	border:solid 1px var(--color);
	border-radius:var(--radius);
	padding:var(--padding);
	background: black;
}
.keno_game .game_bets{
  max-width: 300px;
}
.game_bets_box{
	overflow: auto;
  max-height:none;
}
#keno_game_bets p{
  margin-bottom: var(--padding);
}
.game_bets_clear{
  padding-top: var(--padding_small);
}
#game_bets_clear{
  background-color: var(--bg);
	color:var(--color);
	border-radius: 100%;
	border:solid 1px var(--color);
	width: 50px;
  height: 50px;
  padding: 12px var(--padding);
  display: inline-block;
  cursor: pointer;
}
#game_bets_clear:active, #game_bets_clear:focus, #game_bets_clear:hover{
  background-color: var(--color);
	color:var(--bg);
}
.game_start button{
  margin: var(--padding_small);
}
.game_start button svg, #game_bets_clear svg{
  font-size: 24px;
}
.game_loading_icon{
  width: 200px;
}

/* ROULETTE */
#roulette_bets_canvas{
  cursor: pointer;
}

/* BLACKJACK */
#blackjack_board .tooltip, #poker_texas_holdem_board .tooltip, #poker_5_card_draw_board .tooltip{
  width: 100%;
}
#blackjack_board .mybutton svg, #poker_texas_holdem_board .mybutton svg, #poker_5_card_draw_board .mybutton svg{
  margin-right: var(--padding_small);
}

/* POKER */
.game_container.poker_container .game_box_poker{
  position: relative;
  z-index: 1;
}
.game_container.poker_container .game_start{
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0px;
  right: 0px;
  width: 100%;
  transform: translate(0px, -50%);
}
.poker_canvas_container{
  position: relative;
  z-index: 1;
}
.poker_pot_container, .small_blind_container{ 
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0px;
  right: 0px;
  transform: translate(0px, -50%);
}
.poker_pot, .small_blind{
  background-color: var(--transparent_5);
  border: solid 1px var(--color);
  border-radius: var(--radius);
  color: var(--color);
  display: inline-block;
  width: 100%;
  max-width: 150px;
  padding: var(--padding_small) var(--padding);
}

/* BACCARAT */
.baccarat_table .table_box h3, 
.baccarat_table .table_box .counter{
  padding-bottom: var(--padding_small);
  margin-bottom: var(--padding_small);
  border-bottom: solid 1px var(--color);
}
.baccarat_table .table_box button{
  width: 100%;
  max-width: 130px;
}
.baccarat_canvas_container{
  position: relative;
  z-index: 1;
}
.baccarat_subtitles{
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--transparent_5);
  border: solid 1px var(--color);
  border-radius: var(--radius);
  color: var(--color);
  display: inline-block;
  width: 100%;
  max-width: 100px;
  padding: var(--padding_small) var(--padding);
}
.baccarat_subtitles span{
  padding: var(--padding_small);
}

/* GAME BOARD */
.gameboard{
  background-color: var(--dark_color);
  border-top: solid var(--padding_small) var(--dark_color);
  border-right: solid var(--padding_small) var(--light_color);
  border-bottom: solid var(--padding_small) var(--dark_color);
  border-left: solid var(--padding_small) var(--light_color);
  border-radius: var(--radius_small);
  padding: var(--padding_small) var(--padding);
  color: var(--light_color);
  display: inline-block;
  width: 100%;
}
.gameboard .counter .counter_minus, .gameboard .counter .counter_plus {
  border: solid 1px var(--light_color);
  background-color: var(--light_color);
  color: var(--bg);
}
.gameboard .counter .counter_minus:hover, .gameboard .counter .counter_plus:hover {
  border: solid 1px var(--bg);
}
.gameboard .input_light {
  border: solid 1px var(--light_color);
  background-color: var(--light_color);
  color: var(--bg);
}
.gameboard .button_box {
  padding: var(--padding_small) 0px;
  text-align: center;
}
.gameboard .button_box.button_box_start, .gameboard .button_box.button_box_start .tooltip{
  width: 100%;
  text-align: center;
}
.gameboard .button_fullcolor{
  border: solid 1px var(--light_color)!important;
  background-color: var(--light_color)!important;
}
.gameboard .button_fullcolor:hover{
  background-color: var(--light_color)!important;
  border: solid 1px var(--bg)!important;
  color: var(--bg)!important;
}
.gameboard .button_box .button_fullcolor{
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  max-width: 150px;
  margin-right: var(--padding_small);
  height: 25px;
}
#slots_board .counter{
  margin: var(--padding_small) 0px 0px 0px;
}
#slots_board .counter .counter_minus, #slots_board .counter .counter_plus{
  color: var(--bg);
}
#slots_board .round{
  margin: 0px var(--padding_small);
}

/* DONATE */
#donate{
	opacity: 0;
	position: fixed;
  bottom: var(--padding);
  left: var(--padding);
  z-index: 999999;
  width: 36px;
  height: 36px;
	cursor: pointer;
}
#donate svg{
	fill:var(--color);
}
#donate svg path{
	stroke:var(--bg);
}
#donate.open{
	-webkit-transition: opacity 2.0s ease-in;
	-moz-transition: opacity 2.0s ease-in;
	-o-transition: opacity 2.0s ease-in;
	transition: opacity 2.0s ease-in;
	opacity: 1;
}
.donation_container{
  display: inline-block;
  width: 100%;
  max-width: 600px;
  padding: var(--padding);
  background-color: var(--transparent_5);
  margin-bottom: var(--padding);
}
.donation_box{
  padding: var(--padding);
}
.donation_box h2{
  font-family: 'Roboto Condensed';
}
.donation_box_tabs{
  margin-bottom: var(--padding);
}
.donation_box_tabs li {
  display: inline-block;
  padding: var(--padding_small) var(--padding);
  border: solid 1px var(--color);
  width: 100px;
  cursor: pointer;
  text-align: center;
}
.donation_box_tabs li:first-child {
  border-radius: var(--radius) 0px 0px var(--radius);
}
.donation_box_tabs li:last-child {
  border-radius: 0px var(--radius) var(--radius) 0px;
}
.donation_box_tabs li.active{
  background: var(--transparent_color);
}
.donation_box_content{
  height: 150px;
}
.donation_box_content h2{
  margin-bottom: var(--padding);
}
.donation_box_content p{
  margin-bottom: var(--padding);
}
.donation_link_crypto{
  margin-bottom: var(--padding_small);
}
.donation_link_paypal{
  width: 100%;
  max-width: 150px;
  display: inline-block;
  vertical-align: middle;
  padding: var(--padding_small);
}
.donation_link_paypal a{  
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 25px;
  font-size: 12px;
  line-height: 23px
}
a.donation_button{
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 25px;
  font-size: 12px;
  line-height: 23px;
  max-width: 200px;
  margin: var(--padding_small) 0px;
}
.iban_text, .crypto_text{
  cursor: pointer;
}

/* CAREER */
.career_header_container{
	margin-top: var(--padding);
	border-bottom: solid 1px var(--color);
	text-align: left;
}
.career_header_box{
	display:inline-block;
	vertical-align: middle;
	text-align: center;
	padding: var(--padding);
	margin-left: var(--padding_small);
	border: solid 1px var(--color);
	border-bottom: solid 0px transparent;
	border-radius: var(--padding) var(--padding) 0px 0px;
  width: 100px;
}
.career_header_box:hover, .career_header_box:active, .career_header_box:focus, .career_header_box.open{
	background-color: var(--transparent_color);
}
#career_header_title_dropdown_container{
	position: relative;
	z-index: 2;
}
.career_header_title_container {
  position: absolute;
  z-index: 2;
  width: 100%;
  margin-bottom: var(--padding);
}
.career_header_title {
  padding: var(--padding) 20px;
  border: solid 1px var(--color);
  border-radius: 50px;
  display: inline-block;
  max-width: 600px;
  width: 100%;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
.career_header_title_box_container {
  max-width: 600px;
  width: 100%;
  padding: 0px var(--padding);
  max-height: 105px;
  transition: height .5s ease-in-out;
  height: 0;
  overflow: hidden;
  display: inline-block;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, 0px);
  color: var(--bg);
  top: 52px;
	margin-top: -10px;
}
.career_header_title_box_container.open {
  height: 105px;
}
.career_header_title_box {
  border: solid 1px var(--color);
  border-radius: 0px 0px 20px 20px;
  padding: var(--padding_small) var(--padding);
  overflow: hidden;
  background-color: var(--color);
}
.career_header_title_box div {
  padding: var(--padding_small) 20px;
  border-bottom: solid 1px var(--bg);
}
.career_container{
	text-align: left;
	padding: var(--padding_small);
}
.career_scroll{
	height: 400px;
	overflow-y: auto;
}
.career_box{
  position: relative;
  z-index: 1;
	padding: var(--padding_small) 0px;
	border-bottom: solid 1px var(--color);
}
.career_box:last-child{
	padding: var(--padding_small) 0px;
	border-bottom: solid 0px transparent;
}
.career_info{
  margin-bottom: var(--padding);
}
.career_info h4{
  margin-bottom: var(--padding_small);
}
.career_info ul{
  padding-left: 10px;
}
.career_info li{
  display: list-item;
  list-style: inside circle;
}
.career_box .apply_button{
  position: absolute;
  z-index: 1;
  top: var(--padding);
  right: var(--padding);
}

/* QUESTIONS */
.question_subcategory{
  margin-bottom: 20px;
}
.question_box{
	padding:var(--padding);
}
.question_title{
	padding: var(--padding) 20px;
  border: solid 1px var(--color);
  border-radius: 50px;
  display: inline-block;
  max-width: 600px;
	width: 100%;
	cursor: pointer;
	position: relative;
	z-index:1;
}
.question_title:hover{
	background: var(--transparent_color);
}
.answer_container{
	display:none;
  /* text-align: left; */
}
.answer_container.open{
	display:block;
  padding: var(--padding);
}

/* SPLASH SCREEN */
#splash_screen{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: var(--color);
  font-size: 30px;
  line-height: 50px;
}
#splash_screen .splash_screen_container{
  position: relative;
  z-index: 1;
  height: 400px;
  width: 400px;
}
#splash_screen .content_box{
  position: absolute;
  top: 35px;
  left: 50%;
  transform: translate(-50%, 0px);
  z-index: 10;
  width: 100%;
}
#splash_screen .content-dot {
  border-top: solid 1px var(--color);
  border-bottom: solid 1px var(--color);
  padding: var(--padding_small) 0px;
  margin: var(--padding_small) 0px;
}
#splash_screen .content {
  border-top: solid 1px var(--color);
  border-bottom: solid 1px var(--color);
  padding: var(--padding_small);
}
#splash_screen #logo_icon{
  width: 60px;
}
#splash_screen .splash_subtitle{
  font-family: 'Grenadier';
}
#splash_screen .main-diamond-outer {
  transform: rotate(45deg);
  border: solid 1px var(--color);
  background-color: var(--transparent_5);
  height: 400px;
  width: 400px;
  padding: var(--padding_small);
  position: relative;
  z-index: 1;
}
#splash_screen .main-diamond-inner {
  border: solid 1px var(--color);
  width: 100%;
  height: 100%;
}
#splash_screen .mid-diamond-left,
#splash_screen .mid-diamond-right{
  transform: rotate(45deg);
  border: solid 1px var(--color);
  background-color: var(--transparent_5);
  position:absolute;
  z-index: 1;
  width: 100px;
  height:100px;
}
#splash_screen .small-diamond-left-top,
#splash_screen .small-diamond-left-bottom,
#splash_screen .small-diamond-right-top,
#splash_screen .small-diamond-right-bottom{
  transform: rotate(45deg);
  border: solid 1px var(--color);
  background-color: var(--transparent_5);
  position:absolute;
  z-index: 2;
  width: 40px;
  height:40px;
}
#splash_screen .small-diamond-1,
#splash_screen .small-diamond-2,
#splash_screen .small-diamond-3,
#splash_screen .small-diamond-4{
  transform: rotate(45deg);
  border: solid 1px var(--color);
  background-color: var(--transparent_5);
  position:absolute;
  z-index: 3;
  width: 40px;
  height:40px;
}
#splash_screen .mid-diamond-left {
  left: -125px;
  top:150px;
}
#splash_screen .mid-diamond-right {
  right: -125px;
  top:150px;
}
#splash_screen .small-diamond-left-top {
  top: 140px;
  left: -63px;
}
#splash_screen .small-diamond-left-bottom {
  top: 218px;
  left: -63px;
}
#splash_screen .small-diamond-right-top {
  top: 140px;
  right:-63px
}
#splash_screen .small-diamond-right-bottom {
  top: 218px;
  right:-63px
}
#splash_screen .small-diamond-1{
  top: 0px;
  left: 0px;
}
#splash_screen .small-diamond-2{
  bottom: 0px;
  left: 0px;
}
#splash_screen .small-diamond-3{
  top: 0px;
  right: 0px;
}
#splash_screen .small-diamond-4{
  bottom: 0px;
  right: 0px;
}

.progress_container{
  color: var(--color);
  width: 100%;
  max-width: 280px;
  display: inline-block;
  font-size: 12px;
  line-height: 12px;
  margin-top: var(--padding_small);
}
.progress_box_container{
  background-color: transparent;
  border: solid 1px var(--color);
  padding: var(--padding_small);
}
.progress_box{
  border: solid 1px var(--color);
  background-color: var(--transparent_5);
}
.progress{
    background-color: var(--color);
    height: 1px;
    border-radius: 0px;
}
.progress_text{
  padding: var(--padding_small);
  position: relative;
  z-index: 1;
}
.progress_text span{
  position: absolute;
  right: -15px;
}

/* FORGOT PASSWORD */
.forgotPassword_buttons{
  padding: var(--padding_small) 0px;
}
.forgotPassword_buttons input{
  margin-bottom: var(--padding_small);
}

/* CONTACT */
.contact_box{
  padding: var(--padding);
  border-radius: var(--radius);
  border: solid 1px var(--color);
  margin-bottom: var(--padding);
  text-align: left;
}
.contact_form .input_light{
  margin-bottom: var(--padding_small)!important;
}
.contact_dropdown{
  width: 100%;
  max-width: 150px;
  border-radius: var(--radius);
}
.contact_dropdown .dropdown .dropdown-toggle {
  font-size: 12px;
  line-height: 12px;
  width: 100%;
}
.contact_dropdown .dropdown .dropdown-menu{
  min-width: auto;
}
.contact_list_item{
  padding: var(--padding) var(--padding_big);
  margin-top: var(--padding);
}
.contact_form .label_container, .contact_form input, .contact_list_item li{
  margin-bottom: var(--padding_small);
}
.contact_list_item li:last-child{
  margin-bottom: 0px;
}
.contact_box .alert{
  margin-top: 0px;
  margin-bottom: var(--padding);
}
.contact_box input, .contact_box textarea{
  background-color: var(--transparent_2);
}
.contact_box textarea{
  min-height: 140px;
}
#contact_map{
  background-color: var(--transparent_2);
  padding: 0px;
  overflow: hidden;
}
#contact_map .contactMap{
  height: 300px;
}
#contact_map .leaflet-popup-content-wrapper, #contact_map .leaflet-popup-tip,
#contact_map .leaflet-touch .leaflet-bar a, 
#contact_map .leaflet-touch .leaflet-control-layers-toggle{
  background-color: white;
  border: solid 1px var(--red);
  color: var(--red);
}
#contact_map .leaflet-container a.leaflet-popup-close-button{
  color: var(--red);
}
#contact_map .leaflet-control-layers-selector{
  width: auto;
  height: auto;
}
#contact_map .leaflet-touch .leaflet-control-layers, #contact_map .leaflet-touch .leaflet-bar{
  border: none;
}
#contact_form .dropdown .dropdown-toggle{
  width: 100%;
  height: 25px;
  font-size: 14px;
  line-height: 14px;
}
.contact_address{
  text-align: left;
  margin-bottom: var(--padding);
}

/* DASHBOARD */
.dashboard_box{
  background-color: var(--transparent_2);
  border: solid 1px var(--color);
  border-radius: var(--radius);
  padding: var(--padding);
  margin-top: var(--padding);
}
#dashboard_right_cart, #dashboard_right_orders{
  margin-top: 0px;
  border-radius: 0px 0px var(--radius) var(--radius);
}
.dashboard_left_buttons{
  margin: var(--padding) 0px;
}
.dashboard_left_buttons .mybutton{
  margin-top: var(--padding);
}
.dashboard_buttons{  
  margin-top: var(--padding);
}
.dashboard_box .cart_list, .dashboard_box .order_list{
  max-height: 236px;
  overflow: auto;
  border-bottom: solid 1px var(--color);
}
.dashboard_box .order_list{
  border-bottom: none;
  margin-top: var(--padding);
}
.cart_image, .cart_info, .cart_buttons{
  display: inline-block;
  vertical-align: top;
  padding: var(--padding_small);
  text-align: left;
}
.cart_image{
  text-align: center;
}
.cart_buttons{
  text-align: right;
}
.dashboard_box .cart_image{
  width: 15%;
}
.dashboard_box .cart_info{
  width: 40%;
}
.dashboard_box .cart_buttons{
  width: 45%;
}
.dashboard_box .cart_buttons h4{
  margin-bottom: var(--padding_small);
}
.dashboard_right_buttons{
  position: relative;
  z-index: 1;
  padding-top: var(--padding);  
  text-align: left;
}
.dashboard_right_button{
  display: inline-block;
  vertical-align: top;
  padding: var(--padding_small) var(--padding);
  background-color: transparent;
  color: var(--color);
  border: solid 1px var(--color);
  border-bottom: none;
  border-radius: var(--radius) var(--radius) 0px 0px;
  width: 130px;
  margin-right: var(--padding_small);
  text-align: center;
  cursor: pointer;
}
.dashboard_right_button:hover, .dashboard_right_button.active{
  background-color: var(--color);
  color: var(--bg);
}
.dashboard_right_market{
  position: absolute;
  z-index: 1;
  bottom: 0px;
  right: 0px;
  background-color: var(--color);
  color: var(--bg);
  border: solid 1px var(--color);
  border-bottom: none;
  text-align: center;
  padding: var(--padding_small) var(--padding);
  border-radius: var(--radius) 0px 0px 0px;
  text-align: center;
  cursor: pointer;
}
.dashboard_right_buttons .dropdown{
  background-color: var(--transparent_2);
  margin-bottom: var(--padding);  
}
.dashboard_right_buttons .dropdown .dropdown-toggle{
  width: 100%;
  height: 27px;
  line-height: 14px;
}
.dashboard_right_button svg, .dashboard_right_market svg{
  margin-right: 5px;
}
.crop_vegetables{
	width: 40px;
	height: 40px;
	overflow: hidden;
	display:inline-block!important;
}
.crop_vegetables .vegetable {
	width: 160px!important;
	height: 160px;	
}
.crop_vegetables .garlic {
	margin-top: -80px;
  margin-left: -80px;
}
.crop_vegetables .onion {
	margin-top: 0px;
  margin-left: -40px;
}
.crop_vegetables .radish {
	margin-top: -40px;
    margin-left: -80px;
}
.crop_vegetables .carrot {
	margin-top: 0px;
	margin-left: 0px;
}
.crop_vegetables .turnip {
	margin-top: -120px;
	margin-left: -120px;
}
.crop_vegetables .potato {
	margin-top: 0px;
  margin-left: -80px;
}
.crop_vegetables .cabbage {
	margin-top: -80px;
  margin-left: -40px;
}

.crop_vegetables.big{
	width: 80px;
	height: 80px;
	overflow: hidden;
	display:inline-block!important;
}
.crop_vegetables.big .vegetable {
	width: 320px!important;
	height: 320px;	
}
.crop_vegetables.big .garlic {
	margin-top: -160px;
  margin-left: -160px;
}
.crop_vegetables.big .onion {
	margin-top: 0px;
  margin-left: -80px;
}
.crop_vegetables.big .radish {
	margin-top: -80px;
    margin-left: -160px;
}
.crop_vegetables.big .carrot {
	margin-top: 0px;
	margin-left: 0px;
}
.crop_vegetables.big .turnip {
	margin-top: -240px;
	margin-left: -240px;
}
.crop_vegetables.big .potato {
	margin-top: 0px;
  margin-left: -160px;
}
.crop_vegetables.big .cabbage {
	margin-top: -160px;
  margin-left: -80px;
}

.cart_header, .cart_item, .order_header, .order_item{
  border-bottom: solid 1px var(--color);
  padding: var(--padding_small);
}
.cart_item:last-child, .order_item:last-child{
  border-bottom: solid 0 transparent;
}
.cart_footer{
  padding: var(--padding_small) 0px;
}
.cart .cart_button{
  display: inline-block;
  vertical-align: middle;
  padding: var(--padding_small);
  width: 50%;
}
.cart .remove{
  width: 40px!important;
  height: 40px!important;
}
.cart_action h4{
  margin-bottom: var(--padding_small);
}

.order_list {
  overflow-x: auto;  
}
.order_list table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}
.order_list th, .order_list td {
  padding: 12px 15px;
  border: 1px solid var(--color);
  white-space: nowrap;
  text-align: left;
}
.order_list th.order_item_no, .order_list td.order_item_no,
.order_list th.order_item_buttons, .order_list td.order_item_buttons {
  text-align: center;
}
.order_list th {
  background-color: var(--color);
  color: var(--bg);
  font-weight: bold;
  border-right: 1px solid var(--bg);
}
.order_list th:last-child {
  border-right: 1px solid var(--color);
}
.order_item_no {
  width: 10%;
  min-width: 50px;
}
.order_item_description {
  width: 60%;
  min-width: 200px;
}
.order_item_price {
  width: 20%;
  min-width: 100px;
}
.order_item_buttons{
  width: 10%;
  min-width: 50px;
  cursor: pointer;
}

/* ORDER DETAILS */
.orderDetails_popup{
  text-align: left;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
}
.orderDetails_popup .value{
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
.orderDetails_order_info, .orderDetails_customer_info{
  margin-bottom: var(--padding);
}
.orderDetails_order_info h3, .orderDetails_customer_info h3, .orderDetails_order_detail_items h3{
  padding-bottom: var(--padding_small);
  margin-bottom: var(--padding_small);
  border-bottom: solid 1px var(--bg);
}
.orderDetails_popup .label, .orderDetails_footer .label{
  margin-right: var(--padding_small);
}
.order_detail_status, .order_detail_status.default{
  color: var(--bg);
}
.order_detail_status.green{
  color: var(--green);
}
.order_detail_status.orange{
  color: var(--orange);
}
.order_detail_status.red{
  color: var(--red);
}
.orderDetails_footer{
  text-align: right;
  margin-top: var(--padding);
  padding-top: var(--padding);
  border-top: solid 1px var(--bg);
}
.orderDetails_footer h3{
  font-weight: bold;
}

/* Profile pics */
.dashboard_user_pic{
  position: relative;
  z-index: 1;
}
.dashboard_user_pic, .dashboard_user_info{
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  min-width: 150px;
}
.dashboard_user_info{
  margin-top: var(--padding);
}
.profile_pic_container{
	position: relative;
  z-index: 1;
  width: 120px;
  display: inline-block;
}
.profile_pic_container .profile_pic_box{
  position: relative;
  z-index: 1;
  width: 100%;
  height: 120px;
  overflow: hidden;
  border-radius: 100%;
  border: solid 1px var(--color);
  cursor: pointer;
}
.profile_pic_container .profile_pic{
	width: 100%;
  height: 100%;
	font-size: 40px;
	line-height: 120px;
	position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
.profile_pic_container .profile_pic_default{
	font-size: 40px;
	line-height: 120px;
	position: absolute;
  z-index: 1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	color: var(--color);
	width: 120px;
	height: 120px;
	border-radius: 100%;
	opacity: 0;
	transition: .5s ease;
}
.profile_pic_container .profile_pic_crown{
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  font-size: 24px;
}
.profile_pic_container:hover .profile_pic{
	opacity: 0;
}
.profile_pic_container:hover .profile_pic_default{
	opacity: 1;
}
.profile_pic_name{
  position: relative;
  z-index: 2;
  background-color: var(--color);
  border: solid 1px var(--color);
  border-radius: var(--radius_big);
  color: var(--bg);  
  height: 25px;
  padding: var(--padding_small) var(--padding);
  top: -20px;
  white-space: nowrap;
  margin-bottom: -20px;
}
.profile_pic_edit{
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 0px;
  padding: var(--padding_small);
  cursor: pointer;
}
.profile_pic_input{
	opacity: 0;
	width: 100px;
	height: 100px;
	position: absolute;
  top: 0;
  left: 0;
}
.crop_profile_pic_box {
	display: inline-block;
  cursor: pointer;
  padding: var(--padding_small);	
}
.crop_profile_pic_box p{
  background-color: var(--bg);
	border: solid 1px var(--bg);
	color:var(--color);
	border-radius: var(--radius);
	position: relative;
  z-index: 2;
  top: -15px;
}
.crop_profile_pic_box {
  display: inline-block;
  cursor: pointer;
  padding: var(--padding_small);
}
#carousel_profile .selected .crop_profile_pic{
  border:solid 3px var(--bg);
}
#changeProfilePic_btn{
  width: 100px;
}
.profile_grey{
  margin-bottom: var(--padding);
}
.crop_profile_pic_box:hover .crop_profile_pic{
  border:solid 3px var(--bg);
}

.crop_profile_pic{
	width: 120px;
	height: 120px;
	overflow: hidden;
	display:inline-block!important;
	border-radius: 100%;
	border:solid 1px var(--bg);
}
.crop_profile_pic .profile_pic {
	width: 960px!important;
	height: 120px;	
}
.profile_pic.pic_1 {
	margin-top: 0px;
  margin-left: 0px;
}
.profile_pic.pic_2 {
	margin-top: 0px;
  margin-left: -120px;
}
.profile_pic.pic_3 {
	margin-top: 0px;
  margin-left: -240px;
}
.profile_pic.pic_4 {
	margin-top: 0px;
  margin-left: -360px;
}
.profile_pic.pic_5 {
	margin-top: 0px;
  margin-left: -480px;
}
.profile_pic.pic_6 {
	margin-top: 0px;
  margin-left: -600px;
}
.profile_pic.pic_7 {
	margin-top: 0px;
  margin-left: -720px;
}

#carousel_profile .crop_profile_pic {
  width: 150px;
  height: 150px;
  overflow: hidden;
  display: inline-block !important;
  border-radius: 100%;
  border: solid 1px var(--bg);
}
#carousel_profile .crop_profile_pic .profile_pic {
  width: 1200px !important;
  height: 150px;
}
#carousel_profile .profile_pic.pic_1 {
  margin-top: 0px;
  margin-left: 0px;
}
#carousel_profile .profile_pic.pic_2 {
  margin-top: 0px;
  margin-left: -150px;
}
#carousel_profile .profile_pic.pic_3 {
  margin-top: 0px;
  margin-left: -300px;
}
#carousel_profile .profile_pic.pic_4 {
  margin-top: 0px;
  margin-left: -450px;
}
#carousel_profile .profile_pic.pic_5 {
  margin-top: 0px;
  margin-left: -600px;
}
#carousel_profile .profile_pic.pic_6 {
  margin-top: 0px;
  margin-left: -750px;
}
#carousel_profile .profile_pic.pic_7 {
  margin-top: 0px;
  margin-left: -900px;
}
#carousel_profile .profile_pic.pic_8 {
  margin-top: 0px;
  margin-left: -1050px;
}

.slick-prev, .slick-next{
  width: 30px!important;
  height: 30px!important;
  z-index: 999999;
}
.slick-prev {
  left: 0px!important;
}
.slick-next{
  right: 0px!important;
}
.slick-prev:before, .slick-next:before{
  color: var(--bg)!important;
  font-size: 30px!important;
  opacity: 1!important;
}

/* MARKET */
.shop_shader{	
	background-position: center;
	background-size: 100% auto;
	background-repeat: no-repeat;
	height:130px;
	margin-bottom: -20px;
	position: relative;
	z-index:2;
}
.cell_market{
  height: auto;
}
.counter .counter_minus_box, .counter .counter_plus_box, .counter .counter_input_box{
  display: inline-block;
  vertical-align: middle;
  padding: var(--padding_small);
  text-align: center;
}
.counter .counter_minus_box, .counter .counter_plus_box{
  width: 25%;
}
.counter_input_box{
  width: 50%;
}
.counter .counter_minus, .counter .counter_plus{
  border: solid 1px var(--color);
  color: var(--color);
  border-radius: 100%;
  padding: var(--padding_small);
  width: 25px;
  height: 25px;
  font-size: 24px;
  line-height: 12px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}
.counter_input{
  text-align: center;
}

/* SETTINGS */
.settings .language{
  position: static;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}
.settings .dropdown .dropdown-toggle{
  background-color: var(--bg);
  width: 100%;
  font-size: 12px;
  line-height: 12px;
}
.settings .dropdown-item {
  padding: var(--padding_small);
  font-size: 12px;
  line-height: 12px;
}
.settings > div{
  margin-bottom: var(--padding);
}
.settings .theme_item{
  padding: var(--padding_small);
}
.settings .theme_box{
  border-radius: var(--radius);
  padding: var(--padding_small) var(--padding);
}
.dropdown-item:focus .theme_box, .dropdown-item:active .theme_box, .dropdown-item:hover .theme_box{
  color: var(--bg);
}
.settings .theme_box.green{
  background: green;
  border: solid 1px gold;
  color: gold;
}
.settings .theme_box.purple{
  background: purple;
  border: solid 1px pink;
  color: pink;
}
.settings .theme_box.black{
  background: #262626;
  border: solid 1px #32CD32;
  color: #32CD32;
}
.settings .theme_box.blue{
  background: #1a2633;
  border: solid 1px #ff8000;
  color: #ff8000;
}
.settings .theme_text{
  display: inline-block;
  vertical-align: middle;
}

/* CART */
#cart_panel{
  margin-top: var(--padding);
}
.cart_box{
  background-color: var(--transparent_2);
  border: solid 1px var(--color);
  border-radius: var(--radius);
  padding: var(--padding);
  margin: var(--padding) 0px;
}
.cart_list_items{
  max-height: 317px;
  overflow-x: hidden;
  overflow-y: auto;
}
.cart_total_price, #cart_promo h4, #cart_promo input{
  margin-bottom: var(--padding);  
}
#cart_panel .mybutton{
  margin-bottom: var(--padding);
}

/* CHECKOUT */
#payment_details .payment_details_title h3{
  padding: var(--padding_small);
  margin-bottom: var(--padding_small);
  border-bottom: solid 1px var(--color);
}
.checkbox_radio_container.payment_details_title{
  padding: var(--padding_small);
  margin: 0px 0px var(--padding_small) 0px;
  border-bottom: solid 1px var(--color);
}
.checkbox_radio_container{
  padding: var(--padding_small);
}

#payment_form label{
  margin-bottom: var(--padding_small);
}
#payment_form .dropdown .dropdown-toggle{
  height: 25px;
  width: 100%;
  font-size: 14px;
  line-height: 6px;
}
#payment_form input, #payment_form .dropdown{
  margin-bottom: var(--padding);
}
#payment_form .alert{
  margin-top: 0px;
  margin-bottom: var(--padding);
}
.payment_details_box{
  text-align: left;
}
.payment_details_body, .payment_details_total_price{
  padding: var(--padding_small);
  margin-bottom: var(--padding_small);
}
.paymentSuccess p, .paymentSuccess h3{
  margin-bottom: var(--padding_small);
}
.paymentSuccess h6 span{
  margin-right: var(--padding_small);
}
#payment_form_crypto .checkbox_radio_container{
  display: inline-block;
}
.cardNumber_show, .cardNumber_edit{ 
  position: relative;
  z-index: 1;  
  padding: var(--padding_small);
}
.cardNumber_show{
  display: inline-block;
  width: 100px;
}
.cardNumber_edit{
  width: 100%;
  padding-right: 40px;
  margin-bottom: var(--padding);
}
.cardNumber_button{  
  position: absolute;
  z-index: 1;  
  top: 0px;
  right: 0px;
  padding: var(--padding_small);
  cursor: pointer;
}
.cardNumber_edit input{
  margin-bottom: 0px!important;
}
.cardNumber_edit .cardNumber_button{
  font-size: 25px;
}
.google_pay_button_container{
  height: 30px!important;;
}
.google_pay_button_container .gpay-card-info-container, .google_pay_button_container .gpay-button{
  background-color: transparent !important;
  height: 30px;
  min-height: 30px;
}
.google_pay_button{
  background-color: var(--color) !important;
  border: solid 1px var(--color) !important;
  border-radius: var(--padding_big) !important;
  box-shadow: 4px 4px 8px 2px var(--transparent_8);
  height: 30px;
  width: 100%;
}
.gpay-card-info-container-fill .gpay-card-info-iframe{
  height: 30px !important;
}
.gpay-card-info-container.gpay-card-info-custom-radius, .gpay-button.gpay-card-info-custom-radius{
  outline: solid 0px transparent!important;
}
.button_action_group_checkout, .google_pay_button_container, .paypal_button_container{
  width: 100%;
  max-width: 300px;
  margin: 0px auto;
}

/* ORDER */
.order_box{
  border: solid 1px var(--color);
  border-radius: var(--radius);
  padding: var(--padding);
  background-color: var(--transparent_2);
  margin-bottom: var(--padding);
  text-align: left;
}
.order_box > h3{
  border-bottom: solid 1px var(--color);
  padding-bottom: var(--padding_small);
  margin-bottom: var(--padding_small);
}
.order_details{
  min-height: 211px;
}
.order_details_link{
  border-bottom: solid 1px var(--color);
  padding-bottom: var(--padding_small);
  margin-bottom: var(--padding_small);
}
.status .status_box.succeeded{
  background-color: var(--green_transparent_3);
  border: solid 1px var(--green);
  color: var(--green);
}
.status .status_box{
  background-color: var(--red_transparent_3);
  border: solid 1px var(--red);
  color: var(--red);
  border-radius: var(--radius);
  padding: var(--padding_small) var(--padding);
  display: inline-block;
  margin-left: var(--padding_small);
}
.payment_icons_container{
  padding: var(--padding_small) 0px;
}
.payment_icons_container .payment_icon{
  width: 45px;
  margin-right: var(--padding_small);
}
.payment_icons_container .payment_icon:last-child{
  margin-right: 0px;
}

/* WITHDRAW */
#withdraw_form h3, .checkbox_radio_container.withdraw_details_title{
  padding: var(--padding_small);
  margin-bottom: var(--padding_small);
  border-bottom: solid 1px var(--color);
}
#withdraw_form label{
  margin-bottom: var(--padding_small);
}
#withdraw_form .dropdown .dropdown-toggle{
  height: 25px;
  width: 100%;
  font-size: 14px;
  line-height: 6px;
}
#withdraw_form input, #withdraw_form .dropdown{
  margin-bottom: var(--padding);
}
#withdraw_form .withdraw_form_amount label,
#withdraw_form .withdraw_form_amount input{
  margin-bottom: 0px;
}
#withdraw_form .alert{
  margin-top: 0px;
  margin-bottom: var(--padding);
}
#withdraw_form .button_action_group button{
  margin: var(--padding_small);
}
.instructions_container{
  text-align: right;
}
.instructions_container svg{
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
}
#withdraw_form .captcha_container{
  text-align: right;
}
#withdraw_form .captcha_box{
  display: inline-block;
}
#withdraw_form .captcha_container .alert{
  margin-top: var(--padding);
}

/* SLOTS */
.slot_machine{
	display: inline-block;
  position: relative;
  z-index: 1;
  text-align: center;
  border: var(--padding) solid;
  border-radius: var(--padding_small);
  background: var(--dark_color);
	border-color: var(--dark_color) var(--light_color);
}
#slots_prizes{
  position: absolute;
  z-index: 1;
  top: 50%;
  right: -45px;
  transform: translate(0px, -50%);
  border-radius: 0 var(--padding_small) var(--padding_small) 0;
  background: var(--light_color);
  color: var(--bg);
  border-left: var(--padding_small) solid var(--dark_color);
  padding: var(--padding_small);
  width: 35px;
  height: 150px;
  writing-mode: vertical-lr;
  text-orientation: upright;
  cursor: pointer;
}
#slots_prizes.mobile{
  position: static;
  transform: none;
  width: 100%;
  height: auto;
  writing-mode: unset;
  text-orientation: unset;
  background-color: var(--dark_color);
  border-top: solid var(--padding_small) var(--dark_color);
  border-right: solid var(--padding_small) var(--light_color);
  border-bottom: solid var(--padding_small) var(--dark_color);
  border-left: solid var(--padding_small) var(--light_color);
  border-radius: var(--radius_small);
  margin-top: var(--padding);
}
#slot_results{
	position: absolute;
	z-index: 2;
	top:0px;
	left:0px;
	right:0px;
	bottom:0px;
}
.slot_machine .box{
  overflow: hidden;
	display:inline-block;
  vertical-align: top;
	height: 312px;
  max-width: 110px;
	border: var(--padding_small) solid;
  border-color: var(--dark_color) var(--light_color) var(--dark_color) var(--dark_color);
  background: var(--slot_canvas_color);
}
.slot_machine .box:last-child{
	border-right: var(--padding_small) solid var(--dark_color);
}
.slot_machine .slot_reel{
  position: relative;
  z-index: 1;	
}
.slot_machine_board{
  width: 100%;
  max-width: 570px;
  margin: auto;
  padding-top: var(--padding);
}
.slots_rules{
  padding: var(--padding_small);
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
.slots_rules_box{
  margin-bottom: var(--padding_small);
}
.slots_rules_box td{
  width: 30px;
  height: 30px;
  border: solid 1px var(--bg);
}
.slots_rules_box td.selected{
  background-color: var(--bg);
  border: solid 1px var(--color); 
}
.slots_rules_left, .slots_rules_right{
  display: inline-block;
  vertical-align: top;
  padding: 0px var(--padding_small);
}
.slots_bets{
  margin: var(--padding) 0px;
}
.slots_bets .dropdown .dropdown-toggle{
  font-size: 12px;
  line-height: 12px;
  width: 100%;
  background-color: var(--light_color);
  border: solid 1px var(--light_color); 
  color: var(--bg);
}
.slots_bets .dropdown .dropdown-menu {
  min-width: auto;
  max-height: 100px;
}
.slots_bets_label{
  display: inline-block;
  vertical-align: middle;
  margin-right: var(--padding_small);
  width: 45px;
}
.slots_bets_dropdown{
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 50px);
}

@media (max-width: 767px) {	
  .game_box{
    padding: var(--padding) 40px var(--padding) var(--padding);
  }
  .game_container.poker_container .game_start{
    padding: 0px 40px 0px 0px;
  }
  .slot_machine .box{
    height: 222px;
  }
  .slot_machine_board{
    max-width: 100%;
  }
  .dashboard_right_button{
    width: auto;
  }
  .dashboard_right_button svg, .dashboard_right_market svg{
    margin-right: 0px;
  }
  #blackjack_board .mybutton svg, #poker_texas_holdem_board .mybutton svg, #poker_5_card_draw_board .mybutton svg{
    margin-right: 0px;
  }
  #blackjack_board .mybutton span, #poker_texas_holdem_board .mybutton span, #poker_5_card_draw_board .mybutton span{
    display: none;
  }
}
@media (max-width: 600px) {	
  .slot_machine.slot_machine_5 .box {
    height: 192px;
  }
}
@media (max-width: 480px) {
  .slot_machine{
    border: var(--padding_small) solid;
    border-color: var(--dark_color) var(--color);
  }
  .slot_machine .box {
    border: 3px solid;
    border-color: var(--dark_color) var(--color) var(--dark_color) var(--dark_color);
    height: 218px;
  }
  .slot_machine.slot_machine_5 .box {
    height: 128px;
  }
  .cookies_msg{
    padding: var(--padding);
    text-align: center;
  }
  .cookies_text, .confirm_cookies {
    width: 100%;  
  }
  .confirm_cookies button{
    margin-top: var(--padding_small);
  }
}

/* CRAPS */
.craps_subtitle{
  margin-bottom: var(--padding_big);
}
.dice_box {
	position: relative;
	z-index: 0;
	display: inline-block;
	margin: 0px var(--padding);
  border-radius: 5px;
}
.dice {
	position: relative;
	z-index: 2;
	width: 100px;
	height: 100px;
	transform-style: preserve-3d;
	transition: transform 1s;
}
.dice_box_shadow{
	position: absolute;
	z-index: 1;
	top: 0;
	width: 100px;
	height: 100px;
	border-radius: var(--radius_small);
	background: var(--transparent_5);
  box-shadow: 4px 4px 8px 2px var(--transparent_5);
  width: 100px;
	height: 100px;
}
.dot {
	position: absolute;
	width: 20px;
	height: 20px;
	margin: -10px 5px 5px -10px;
	border-radius: 20px;
	background-color: black;
	box-shadow: inset 2px 2px #333;
}
.side {
	position: absolute;
	background-color: var(--darkred);
	border-radius:5px;
	width: 100px;
	height: 100px;
	border: 1px solid var(--red);
	text-align: center;
	line-height: 2em;
}

.side:nth-child(1) {
	-webkit-transform: translateZ(50px);
  -moz-transform: translateZ(50px);
  -ms-transform: translateZ(50px);
  -o-transform: translateZ(50px);
	transform: translateZ(50px); 
}
.side:nth-child(6) {
	-webkit-transform: rotateY(90deg) translateZ(50px);
  -moz-transform: rotateY(90deg) translateZ(50px);
  -ms-transform: rotateY(90deg) translateZ(50px);
  -o-transform: rotateY(90deg) translateZ(50px);
	transform: rotateY(90deg) translateZ(50px); 
}
.side:nth-child(3) {
	-webkit-transform: rotateY(-90deg) translateZ(50px);
  -moz-transform: rotateY(-90deg) translateZ(50px);
  -ms-transform: rotateY(-90deg) translateZ(50px);
  -o-transform: rotateY(-90deg) translateZ(50px);
	transform: rotateY(-90deg) translateZ(50px); 
}
.side:nth-child(4) {
	-webkit-transform: rotateX(90deg) translateZ(50px);
  -moz-transform: rotateX(90deg) translateZ(50px);
  -ms-transform: rotateX(90deg) translateZ(50px);
  -o-transform: rotateX(90deg) translateZ(50px);
	transform: rotateX(90deg) translateZ(50px); 
}
.side:nth-child(5) {
	-webkit-transform: rotateX(-90deg) translateZ(50px);
  -moz-transform: rotateX(-90deg) translateZ(50px);
  -ms-transform: rotateX(-90deg) translateZ(50px);
  -o-transform: rotateX(-90deg) translateZ(50px);
	transform: rotateX(-90deg) translateZ(50px); 
}
.side:nth-child(2) {
	-webkit-transform: rotateY(-180deg) translateZ(50px);
  -moz-transform: rotateY(-180deg) translateZ(50px);
  -ms-transform: rotateY(-180deg) translateZ(50px);
  -o-transform: rotateY(-180deg) translateZ(50px);
	transform: rotateY(-180deg) translateZ(50px); 
}

.show_1 {
	-webkit-transform: rotateX(720deg) translateZ(-720deg);
  -moz-transform: rotateX(720deg) translateZ(-720deg);
  -ms-transform: rotateX(720deg) translateZ(-720deg);
  -o-transform: rotateX(720deg) translateZ(-720deg);
	transform: rotateX(720deg) rotateZ(-720deg);
}
.show_2 {
	-webkit-transform: rotateX(-900deg) translateZ(1080deg);
  -moz-transform: rotateX(-900deg) translateZ(1080deg);
  -ms-transform: rotateX(-900deg) translateZ(1080deg);
  -o-transform: rotateX(-900deg) translateZ(1080deg);
	transform: rotateX(-900deg) rotateZ(1080deg); 
}
.show_3 {
	-webkit-transform: rotateY(810deg) translateZ(720deg);
  -moz-transform: rotateY(810deg) translateZ(720deg);
  -ms-transform: rotateY(810deg) translateZ(720deg);
  -o-transform: rotateY(810deg) translateZ(720deg);
	transform: rotateY(810deg) rotateZ(720deg); 
}
.show_4 {
	-webkit-transform: rotateX(-810deg) translateZ(-1080deg);
  -moz-transform: rotateX(-810deg) translateZ(-1080deg);
  -ms-transform: rotateX(-810deg) translateZ(-1080deg);
  -o-transform: rotateX(-810deg) translateZ(-1080deg);
	transform: rotateX(-810deg) rotateZ(-1080deg); 
}
.show_5 {
	-webkit-transform: rotateX(450deg) translateZ(-720deg);
  -moz-transform: rotateX(450deg) translateZ(-720deg);
  -ms-transform: rotateX(450deg) translateZ(-720deg);
  -o-transform: rotateX(450deg) translateZ(-720deg);
	transform: rotateX(450deg) rotateZ(-720deg);
}
.show_6 {
	-webkit-transform: rotateY(-450deg) translateZ(-1440deg);
  -moz-transform: rotateY(-450deg) translateZ(-1440deg);
  -ms-transform: rotateY(-450deg) translateZ(-1440deg);
  -o-transform: rotateY(-450deg) translateZ(-1440deg);
	transform: rotateY(-450deg) rotateZ(-1440deg); 
}

.two_1, .three_1, .four_1, .five_1, .six_1 {top: 20%;left: 20%;}
.four_3, .five_3, .six_4 {top: 20%;left: 80%;}
.one_1, .three_2, .five_5 {top: 50%;left: 50%;}
.four_2, .five_2, .six_3 {top: 80%;left: 20%;}
.two_2, .three_3, .four_4, .five_4, .six_6 {top: 80%;left: 80%;}
.six_2 {top: 50%;left: 20%;}
.six_5 {top: 50%;left: 80%; }

.bets_container{
  display: inline-block;
  width: 100%;
  max-width: 250px;
  margin-top: var(--padding);
}

.craps_board_box{
	padding:var(--padding);	
	color:var(--color);
	background-color: var(--transparent_5);
	font-family:courier new;
}
#craps_board {
	resize: none;
	/* width:100%; */
	height:200px;	
	padding: var(--padding_small);
  margin: var(--padding_small);
	cursor: default;
	overflow-y:auto;
}

.craps_board_text{
	padding: var(--padding_small);
	border-bottom: solid 1px var(--color);
	margin-bottom: var(--padding_small);
}
.craps_board_text:last-child{
  border-bottom: solid 0px transparent;
	margin-bottom: 0px;
}
.craps_board_text .text{
	padding: 0px var(--padding_small);
}
.craps_board_text .text01, .craps_board_text .text03, .craps_board_text .text05{
	font-weight: bold;
}

/* RACE LIST*/
.rabbit_box_container{
	padding: var(--padding);
}
.rabbit_box{
	border: solid 1px var(--color);
  border-radius: var(--radius);
  background-color: var(--transparent_2);
  padding: 15px;
}
.rabbit_box_pic{
	position: relative;
	z-index:1;
}
.rabbit_box_name{
	display: inline-block;
	padding:var(--padding_small);
	background-color: var(--color);
	color:var(--bg);
	border-radius:50px;
	width:100%;
  max-width: 200px;
	margin-top:-10px;
}
.rabbit_box_nr{
	position: absolute;
	z-index: 2;
	top:0px;
	left:0px;	
	padding:var(--padding_small);
	border-radius:100px;
	width:30px;
	height:30px;
	border:solid 1px white;
	color:white;
	text-align: center;
}
.rabbit_box_nr.red{
	background-color: red;
}
.rabbit_box_nr.orange{
	background-color: orange;
	border:solid 1px black;
	color:black;
}
.rabbit_box_nr.yellow{
	background-color: yellow;
	border:solid 1px black;
	color:black;
}
.rabbit_box_nr.green{
	background-color: green;
}
.rabbit_box_nr.blue{
	background-color: blue;
}
.rabbit_box_nr.purple{
	background-color: purple;
}
.race_game_container{
  position: relative;
  z-index: 1;
}
.race_order_container{
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 0px;
  right: 0px;
  width: 100%;
  text-align: center;
}
#race_order{
  display: inline-block;
  padding: var(--padding_small) var(--padding) var(--padding) var(--padding);
  background-color: var(--transparent_color);
  border: solid 1px var(--color);
  border-radius: 0px 0px var(--radius_big) var(--radius_big);
}
#race_order .rabbit_box_nr{
  position: static;
  margin: 0px var(--padding_small);
  display: inline-block;
  vertical-align: middle;
}

.rabbit_box_pic .box_pic{
  width:150px;
  height:150px;
  overflow: hidden;
  border-radius:100%;
	border:solid 1px var(--color);
  background-color: var(--transparent_color);
	display:inline-block!important;
  position: relative;
  z-index: 1;
}
.rabbit_box_pic img{
	width:160px!important;
  -webkit-filter: drop-shadow(5px 5px 5px var(--transparent_8));
  filter: drop-shadow(5px 5px 5px var(--transparent_8));
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
}
.rabbit_box .dropdown .dropdown-toggle{
  width: 100%;
  height: 25px;
  line-height: 12px;
}
.star{
  width: 25px!important;
  display:inline-block!important;
  vertical-align: middle;
  padding: var(--padding_small) 2px;
}
.rabbit_box_bet, .rabbit_box_place{
  margin-top: var(--padding_small);
}

/* RACE */
.race_container{
	position:relative;
	z-index: 1;
}
#race_canvas{
	border:solid 1px var(--color);
	border-radius: var(--radius);
	background-image: radial-gradient(transparent, black);
  margin-bottom: var(--padding);
}
.race_game_container .button_action_group{
  margin-top: var(--padding);
}

/* KENO */
.keno_board{
  display: inline-block;
  background-color: var(--transparent_5);
  border: solid 1px var(--color);
  border-radius: var(--radius);
  padding: 2px;
  text-align: center;
  margin-bottom: var(--padding);
}

.keno_spot{
  display: inline-block;
  vertical-align: middle;
  padding: 2px;
}
.keno_spot_box{
  border: 1px solid var(--color);
  border-radius: var(--radius_small);
  width: 60px;
  height: 60px;
  font-size: 20px;
  line-height: 40px;
  padding: var(--padding);
  cursor: pointer;
}
.keno_spot_box:hover{
  background-color: var(--transparent_color);
}
.keno_spot_box.selected{
  background-color: var(--color);
  color: var(--bg);
}
.keno_board_container{
  margin-bottom: var(--padding_big);
}
.keno_buttons_container, .keno_prize_container, .keno_quickpick_container, .keno_bets_container{
  margin-bottom: var(--padding);
}
.keno_buttons_container{
  text-align: right;
}
.keno_prize_container{
  margin-top: var(--padding);
  text-align: left;
}
.keno_prize_container button{
  width: 100%;
  max-width: 200px;
}
.keno_bets{
  display: inline-block;
  vertical-align: top;
  padding: 0px var(--padding_small);
  width: 50%;
}
.keno_bets .dropdown{
  margin-top: var(--padding);
}
.keno_bets .dropdown .dropdown-toggle, .keno_bets .dropdown .dropdown-toggle{
  width: 100%;
  height: 25px;
  line-height: 12px;
}
.keno_rules{
  padding: var(--padding_small);
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
.keno_rules_box{
  margin-bottom: var(--padding_small);
}
.keno_rules_box_header{
  background-color: var(--bg);
  border: solid 1px var(--bg);
  padding: var(--padding_small);
  color: var(--color);
  border-radius: var(--radius) var(--radius) 0px 0px;
}
.keno_rules_box_body{
  border: solid 1px var(--bg);
  border-top: solid 0px transparent;
  border-radius: 0px 0px var(--radius) var(--radius);
}
.keno_rules_items {
  background-color: transparent;
}
.keno_rules_items:hover{
  background-color: var(--transparent_2);
}
.keno_rules_items:first-child:hover {
  background-color: transparent;
}
.keno_rules_item{
  display: inline-block;
  vertical-align: top;
  padding: var(--padding_small);
  border-right: solid 1px var(--bg);
  width: 50%;
}
.keno_rules_item:last-child{
  border-right: solid 0px transparent;
}
.keno_rules_items:first-child .keno_rules_item{
  border-bottom: solid 1px var(--bg);
}
.keno_spot_box.loser {
  background-color: var(--red_transparent_3);
  border: solid 1px var(--red);
  color: var(--red);
}
.keno_spot_box.winner {
  background-color: var(--green_transparent_3);
  border: solid 1px var(--green);
  color: var(--green);
}
.keno_animation_tube, .keno_animation_balls{
  display: inline-block;
  vertical-align: middle;
  padding: var(--padding);
}
#keno_tube_canvas{
  background-color: var(--transparent_color);
  border: solid 1px var(--color);
  border-radius: 20px;
}

/* FIREWORKS */
@keyframes firework {
  0% { 
    /* transform: translate(-50%, 60vh);
    width: 0.5vmin;
    opacity: 1; */
    width: 0.5vmin;
    opacity: 0;
  }
  10% { 
    width: 0.5vmin;
    opacity: 1;
  }
  100% { 
    width: 45vmin; 
    opacity: 0; 
  }
}

.firework,
.firework::before,
.firework::after {
  --top: 60vh;
  content: "";
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5vmin;
  aspect-ratio: 1;
  background:
    radial-gradient(circle, var(--firework_color) 0.2vmin, #000 0) 50% 00%,
    radial-gradient(circle, var(--firework_color) 0.3vmin, #000 0) 00% 50%,
    radial-gradient(circle, var(--firework_color) 0.5vmin, #000 0) 50% 99%,
    radial-gradient(circle, var(--firework_color) 0.2vmin, #000 0) 99% 50%,
    radial-gradient(circle, var(--firework_color) 0.3vmin, #000 0) 80% 90%,
    radial-gradient(circle, var(--firework_color) 0.5vmin, #000 0) 95% 90%,
    radial-gradient(circle, var(--firework_color) 0.5vmin, #000 0) 10% 60%,
    radial-gradient(circle, var(--firework_color) 0.2vmin, #000 0) 31% 80%,
    radial-gradient(circle, var(--firework_color) 0.3vmin, #000 0) 80% 10%,
    radial-gradient(circle, var(--firework_color) 0.2vmin, #000 0) 90% 23%,
    radial-gradient(circle, var(--firework_color) 0.3vmin, #000 0) 45% 20%,
    radial-gradient(circle, var(--firework_color) 0.5vmin, #000 0) 13% 24%;
  background-size: 0.5vmin 0.5vmin;
  background-repeat: no-repeat;
  animation: firework 2s infinite;
}
.firework::before {
  transform: translate(-50%, -50%) rotate(25deg) !important; 
}
.firework::after {
  transform: translate(-50%, -50%) rotate(-37deg) !important;
}

/* POPUP WELCOME */
.welcome_popup p{
  font-size: 30px;
  line-height: 30px;
}
.welcome_popup img{
	width:200px;
}

/* POPUP STREAK */
#streak{
  text-align: center;
}
.streak_text{
  margin-bottom: var(--padding);
}
.progress_bubble_container{
  display: inline-block;
  width: 100%;
  max-width: 600px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.bubble{
  background: var(--color);
  color: var(--bg);
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display: inline-block;
  border: 1px solid var(--bg);
  margin: 2px;
  font-size: 14px;
  line-height: 28px;
}
.bubble.active{
  background: var(--bg);
  color: var(--color);
}

/* HOW TO PLAY */
.list_games_table_container{
  overflow: hidden;
  height: 0px;
  -webkit-transition: height 1s ease;
    -moz-transition: height 1s ease;
      -o-transition: height 1s ease;
      -ms-transition: height 1s ease;
          transition: height 1s ease;
}
.list_games_table_container.open{
  height: 160px;
}
.how_to_play_titles{
  text-align: center;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
.cell_howToPlay_container{
  width: 100%;
  max-width: 150px;
  display: inline-block;
  vertical-align: top;
  padding: var(--padding_small);
}
.cell_howToPlay{
  width: 100%;
  height: 160px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.cell_howToPlay .cell_info{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: var(--padding);
}
.cell_howToPlay .cell_text{
  position: absolute;
  bottom: -15px;
  left: var(--padding);
  right: var(--padding);
  padding: var(--padding_small) var(--padding);
  border-radius: var(--radius_big);
  border: solid 1px var(--color);
  background-color: var(--color);
  color: var(--bg);

}
.cell_howToPlay img{
  width: 100%;
}
.cell_howToPlay p{
  margin: 0px;
}
.box_scroll{  
  margin-bottom: var(--padding);
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
.list_games_table{
  text-align: left;
}
.list_games_table h2, .list_games_table h3{
  margin: var(--padding_small) 0px;
}
.list_games_table ul{
  padding-left: 20px;
}
.list_games_table ul li{
  list-style: circle;
  display: list-item;
}

/* Whack A Rabbit */
.whack_a_rabbit_container{
  position: relative;
  z-index: 1;
}
#whack_canvas, #whack_canvas_hammer{
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0px);
}
#whack_canvas_hammer{
  z-index: 2;
}
#whack_canvas{
  border: solid 1px var(--color);
  border-radius: var(--radius_big);
  background-color: var(--transparent_2);
}
.whack_a_rabbit_header{
  display: inline-block;
  background-color: var(--color);
  color: var(--bg);
  padding: var(--padding_small) var(--padding);
  border-radius: var(--radius) var(--radius) 0px 0px;
  font-size: 14px;
  line-height: 14px;
  width: 100%;
  max-width: 150px;
}
.whack_a_rabbit_header p{
  display: inline-block;
  vertical-align: middle;
  margin-right: var(--padding_big);
}
.whack_a_rabbit_header p:last-child{
  margin-right: 0px;
}
.whack_a_rabbit_canvas{
  position: relative;
  z-index: 1;
  top: 0px;
  left: 0px;
  right: 0px;
}

/* OTHER PAGES */
.other_page_container{
  text-align: left;
  padding-bottom: var(--padding);
}
.other_page_container h3{
  margin-top: var(--padding)
}
.other_page_container h3:first-child{
  margin-top: 0px
}
.other_page_container ul{
	margin-left: 30px;
}
.other_page_container li{
	display: list-item;
  list-style: circle;
}
#about_how_to_play, #about_contact{
  margin: var(--padding);
  cursor: pointer;
}
#about_how_to_play svg, #about_contact svg{
  margin-right: var(--padding_small);
}
#about_how_to_play:hover, #about_contact:hover{
  text-decoration: underline;
}

/* 401 */
.page_not_found_box{
  display: inline-block;
  width: 100%;
  max-width: 600px;
  margin-bottom: var(--padding);
}
.page_not_found_box table, .page_not_found_box tr{
  width: 100%;
}
.page_not_found_box img{
  width: 100%;
}
.page_not_found_box .title{
  margin-bottom: var(--padding);
}
.page_not_found_box tr{
  width: 100%;
}
.page_not_found_img{
  width: 20%;
  max-width: 100px;
}
.page_not_found_text .button_action_group{
  margin-top: var(--padding);
}

/* SPINNER */
.spinner-container {
  text-align: center;
}
.spinner {
  display: inline-block;
  vertical-align: middle;
  width: 100%
}
.spinner > div { 
  border-radius: 100%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out both;
  margin: 0px 2px;
}
.spinner-small > div{
  width: 5px;
  height: 5px;
}
.spinner-medium > div {
  width: 10px;
  height: 10px;
}
.spinner-large > div {  
  width: 15px;
  height: 15px;
}
.spinner .bounce1 {
  animation-delay: -0.32s;
}
.spinner .bounce2 {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.spinner-text {  
  font-size: 14px;
  line-height: 14px;
  padding-top: var(--padding_small);
}

/* CHATBOT */
.chatbot_button_container_big{
  text-align: right;
}
.chatbot_button_container_small{
  position: absolute;
  z-index: 9999;
  top: -60px;
  right: 10px;
}
.chatbot_textarea_container{
  border: solid 1px var(--bg);
  padding: var(--padding);
  margin-bottom: var(--padding);
  background-color: var(--transparent_color);
  position: relative;
  z-index: 1;
  height: 50vh;
  border-radius: var(--radius);
  overflow: hidden;
}
#chatbot_button img{
  width: 100%;
}
@media (max-height: 1200px) {
  .chatbot_textarea_container {
      height: 50vh;
  }
}

@media (max-height: 600px) {
  .chatbot_textarea_container {
      height: 60vh; /* 50% of viewport height */
  }
}

@media (max-height: 480px) {
  .chatbot_textarea_container {
      height: 60vh; /* 60% of viewport height */
  }
}
.chatbot_textarea_container:before{
	content: "";
	background-image: url('./../img/noise.png'), url('./../img/bg001.jpg');
	background-repeat: repeat, repeat;
	background-size: auto, 200px auto;
	position:absolute;
	z-index: 1;
	top:0px;
	bottom:0px;
	left:0px;
	right:0px;
	width:100%;
  opacity: 0.4;
}
.chatbot_textarea_container:after{
	content: "";
	background-color:transparent;
	background-image: radial-gradient(rgba(0,0,0,0.2), black);
	position:absolute;
	z-index: 1;
	top:0px;
	bottom:0px;
	left:0px;
	right:0px;
	width:100%;
}
.chatbot_textarea{
  position: absolute;
  z-index: 2;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  padding: var(--padding);
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  overflow: auto;  
}
.chatbot_input{
  background-color: var(--bg);
  color: var(--color)!important;
  height: 30px;
  border-radius: var(--padding_big);
  padding: var(--padding_small) var(--padding);
}
.chatbot_textarea .bot_message{
  text-align: left;
}
.chatbot_textarea .user_message{
  text-align: right;
}
.chatbot_textarea .message_box{
  background-color: var(--transparent_color);
  border: solid 1px var(--color);
  color: var(--color);
  border-radius: var(--radius_small);
  padding: var(--padding_small) var(--padding);
  margin-bottom: var(--padding_small);
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 16px;
}

/* Email verification */
.email_verification_box p{
  margin: var(--padding) 0px;
}

/* Apply for job */
.applyJob_box{
  margin-bottom: var(--padding);
}

/* Verify email popup */
.verificationSend button{
  margin-top: var(--padding);
}
.verificationSend .resendVerificationResult{
  margin-top: var(--padding);
}

/* Gambling warning popup */
.warningGambling_container{
  text-align: center;
}
.warningGambling{
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 400px;
}
.warningGambling_box{
  display: inline-block;
  vertical-align: top;
  padding: var(--padding_small);
  width: 33%;  
}
.warningGambling_box img{
  width: 100%;
  max-width: 100px;
}
.warningGambling_box p{
  margin-top: var(--padding_small);
}
.warningGambling_container h4{
  margin-top: var(--padding_small);
  padding-top: var(--padding_small);
  border-top: solid 1px var(--darkred);
}

/* media */
@media (max-width: 1600px){
	.shop_shader{
		height:100px;
	}
}
@media (max-width: 1400px){
	.shop_shader{
		height:85px;
	}  
}
@media (max-width: 1200px){
  .dashboard_right_list{
    padding: 0px 50px 0px 0px;
  }
  .dashboard_right_market{
    border-radius: var(--radius);
  }
  .dashboard_right_button span, .dashboard_right_market span{
    display: none;
  }
  .dashboard_right_button svg, .dashboard_right_market svg{
    margin: 0px;
  }
	.shop_shader{
		height:70px;
	}
}

@media (max-width: 1024px){
	.shop_shader{
		height:60px;
	}
  .rabbit_box_pic .box_pic{
    width:130px;
    height:130px;
  }
  .star{
    width: 20px !important;
  }
}
@media (max-width: 992px){
  /* DASHBOARD */
  .dashboard_box{
    background-color: transparent;
    border: none;
    border-radius: 0px;
    box-shadow: none!important;
    padding: 0px;
  }
  .dashboard_user_pic, .dashboard_user_info{
    width: 100%;
    margin: 0px;
  }
  #dashboard_left .mybutton{
    display: inline-block;
    max-width: 200px;
  }
  .profile_pic_name{
    width: 100%;
    max-width: 200px;
    margin: 0px auto;
  }
  .dashboard_user_info_text, .dashboard_left_buttons{
    text-align: left; 
  }
}
@media (max-width: 960px){
  /* MARKET */
	.shop_shader{
		height:50px;
	}
  .progress_bubble_container{
    max-height: 205px;
  }
  /* HOW TO PLAY */    
  .box_scroll{
    max-height: 400px;
  }

  /* TOOLTIP */
  .tooltip .tooltiptext{
    opacity: 0!important;
  }

  /* POKER */
  .poker_pot_container{
    position: static;
    margin-bottom: var(--padding);
  }

  /* RACE */
  .rabbit_box_pic .box_pic{
    width:150px;
    height:150px;
  }
  .rabbit_box_pic img {
    width: 160px !important;
  }
  .star {
    width: 25px !important;
  }

  /* ORDER */
  .payment_icons_container .payment_icon{
    width: 40px;
  }
}

@media (max-width: 767px) {
  #logo_icon{
    width: 30px;
  }
	h1 {
		font-size: 30px;
		line-height: 32px;
	}
	h2 {
		font-size: 18px;
		line-height: 20px;
	}
	h3 {
		font-size: 16px;
		line-height: 18px;
	}
	h4 {
		font-size: 14px;
		line-height: 16px;
	}	
	h6 {
		font-size: 10px;
		line-height: 12px;
	}
  .footer_container.up .footer_body{
    height: 420px;
  }
  .footer_list, .newsletter_container, footer {
    padding: var(--padding);
    padding-bottom: 0px;
    text-align: center;
  }
  .newsletter_container{
    padding-bottom: var(--padding_small);
  }
  footer{
    border-top: solid 0px transparent;
  }
  .modal-body{
    padding: var(--padding);
  }
  
  input, textarea, #payment_form .dropdown .dropdown-toggle, #withdraw_form .dropdown .dropdown-toggle, .slots_bets_dropdown .dropdown .dropdown-toggle{
    font-size: 12px;
    line-height: 6px;
    height: 20px;
  }
  .sign_container .input_container .input_eye{
    top: 3px;
  }

  /* GAMES */
  .game_loading_icon{
    width: 150px;
  }
  .mybutton.round,#game_bets_clear{
    width: 40px;
    height: 40px;
  }
  #game_bets_clear svg{
    font-size: 18px;
  }
  #slots_board .round{
    margin: var(--padding_big) 0px;
  }

  /* DASHBOARD */  
  .dashboard_user_info, .dashboard_user_info_text, .dashboard_left_buttons{
    text-align: center;
  }
  #dashboard_left .mybutton{
    display: block;
    margin: var(--padding_small) auto;
    max-width: 200px;
  }

  /* KENO */
  .keno_spot_box{
    width: 40px;
    height: 40px;
    font-size: 16px;
    line-height: 30px;
    padding: var(--padding_small);
  }
  .keno_prize_container {
    margin-top: var(--padding_small);
  }

  /* RACE */
  .rabbit_box_pic .box_pic{
    width:200px;
    height:200px;
  }
  .rabbit_box_pic img {
    width: 210px !important;
  }
  .star {
    width: 25px !important;
  }

  /* MARKET */
  .shop_shader {
    height: 45px;
  }  

  /* CAREER */
  .career_header_container{
    border: none;
  }
  .career_header_container .dropdown .dropdown-toggle{
    width: 100%;
  }
  .career_scroll{
    height: 380px;
  }

  /* SPLASH SCREEN */
  #splash_screen .splash_screen_container{
    height: 300px;
    width: 300px;
  }
  #splash_screen .content_box{
    top: 28px;
  }
  #splash_screen #logo_splash{
    width: 40px;
  }
  #splash_screen .main-diamond-outer {
    height: 300px;
    width: 300px;
  }
  #splash_screen .mid-diamond-left,
  #splash_screen .mid-diamond-right{
    width: 60px;
    height:60px;
  }
  #splash_screen .small-diamond-left-top,
  #splash_screen .small-diamond-left-bottom,
  #splash_screen .small-diamond-right-top,
  #splash_screen .small-diamond-right-bottom,
  #splash_screen .small-diamond-1,
  #splash_screen .small-diamond-2,
  #splash_screen .small-diamond-3,
  #splash_screen .small-diamond-4{
    width: 30px;
    height:30px;
  }
  #splash_screen .mid-diamond-left {
    left: -85px;
    top: 120px;
  }
  #splash_screen .mid-diamond-right {
    right: -85px;
    top: 120px;
  }
  #splash_screen .small-diamond-left-top {
    top: 110px;
    left: -52px;
  }
  #splash_screen .small-diamond-left-bottom {
    top: 158px;
    left: -52px;
  }
  #splash_screen .small-diamond-right-top {
    top: 110px;
    right:-52px;
  }
  #splash_screen .small-diamond-right-bottom {
    top: 158px;
    right:-52px;
  }
  .progress_container{
    max-width: 250px;
  }
  .progress_text{
    width: 100% !important;
  }
  .progress_text span {
    position: static;
  }

  /* LOADER */
  .loader_container .loader img{
		width:100px;
	}  

  /* CART */
  .cart_list_items{
    max-height: 200px;
  }
  .cart .cart_button span{
    display: none;
  }
  .dashboard_box .cart_list{
    max-height: 116px;
  }  

  /* ORDER */
  .order_details{
    min-height: auto;
  } 
  .dashboard_box .order_list{
    max-height: 177px;
  } 

  /* CRAPS */
  .dice {
		width: 60px;
		height: 60px;
	}
	.dice_box_shadow{
		width: 60px;
		height: 60px;
	}
	.dot {
		width: 10px;
		height: 10px;
		margin: -5px 2px 2px -5px;
	}
	.side {
		width: 60px;
		height: 60px;
	}
	.side:nth-child(1) {transform: translateZ(30px); }
	.side:nth-child(6) {transform: rotateY(90deg) translateZ(30px); }
	.side:nth-child(3) {transform: rotateY(-90deg) translateZ(30px); }
	.side:nth-child(4) {transform: rotateX(90deg) translateZ(30px); }
	.side:nth-child(5) {transform: rotateX(-90deg) translateZ(30px); }
	.side:nth-child(2) {transform: rotateY(-180deg) translateZ(30px); }
  #craps_board{
		height: 110px;
	}
  .craps_container .game_start{
    margin: var(--padding_small) 0px;
  }  

  .how_to_play_titles{
    max-height: 380px;
  }

  .warningGambling_box {
    width: 50%;
  }

  /* BACCARAT */
  .table_box{
    margin: var(--padding) auto;
    max-width: 200px;
  }
  .baccarat_table .table_box button{
    max-width: 100px;
  }  
}
@media (max-width:600px) {
  /* CRAPS */
  .game_bets_container{
    padding: 0px 40px;
  }

  /* RACE */
  #race_order{
    padding: var(--padding_small);
    border-radius: 0px 0px var(--radius) var(--radius);
  }
  #race_order .rabbit_box_nr{
    width: 20px;
    height: 20px;
    line-height: 10px;
  }

  /* KENO */
  .keno_board{
    width: 100%;
    overflow: auto;
    height: 310px;
    padding: var(--padding_small);
  }
  .keno_buttons_container, .keno_prize_container{
    text-align: center;
  }
  .keno_prize_container button{
    height: 25px;
  }

  /* SIGN */
  #splash_screen #logo_splash{
    width: 30px;
  }
  .progress_box_container{
    display: none;
  }
  .sign_container input{
    max-width: 100%;
  }
  .sign_container .input_container .input_eye{
    right: 20px;
  }

  /* SALON */
  .salon_games_dropdown .dropdown .dropdown-menu{
    max-height: 140px;
  }
  .panel_container{
    right:-265px;
    width: 300px;
  }
  .panel_box{
    width: 265px;
  }
  .salon_button, .panel_button {
    height: 40px;
  }
  .salon_button span, .panel_button span{
    display: none;
  }
  .chat_list{
    max-height: 100px;
  }

  /* SPLASH SCREEN */
  #splash_screen .splash_screen_container{
    height: 200px;
    width: 200px;
  }
  #splash_screen .content_box{
    top: 12px;
  }
  #splash_screen #logo_splash{
    width: 30px;
  }
  #splash_screen .main-diamond-outer {
    height: 200px;
    width: 200px;
  }
  #splash_screen .mid-diamond-left,
  #splash_screen .mid-diamond-right{
    width: 40px;
    height:40px;
  }
  #splash_screen .small-diamond-left-top,
  #splash_screen .small-diamond-left-bottom,
  #splash_screen .small-diamond-right-top,
  #splash_screen .small-diamond-right-bottom,
  #splash_screen .small-diamond-1,
  #splash_screen .small-diamond-2,
  #splash_screen .small-diamond-3,
  #splash_screen .small-diamond-4{
    width: 20px;
    height:20px;
  }
  #splash_screen .mid-diamond-left {
    left: -60px;
    top: 80px;
  }
  #splash_screen .mid-diamond-right {
    right: -60px;
    top: 80px;
  }
  #splash_screen .small-diamond-left-top {
    top: 62px;
    left: -36px;
  }
  #splash_screen .small-diamond-left-bottom {
    top: 118px;
    left: -36px;
  }
  #splash_screen .small-diamond-right-top {
    top: 62px;
    right:-36px;
  }
  #splash_screen .small-diamond-right-bottom {
    top: 118px;
    right:-36px;
  }

  /* CHECKOUT */
  .payment_details_box{
    text-align: center;
  }
  .payment_details_body{
    border-bottom: solid 1px var(--color);
  }

  /* CHATBOT */
  .chatbot_textarea{
    font-size: 12px;
    line-height: 14px;
  }
}
@media (max-width:480px) {
  /* SALON */
  .header{
    margin-bottom: var(--padding_small);
  }
  #header_salon #logo_icon {
    width: 20px;
  }
  #header_salon h1{
    font-size: 30px;
    line-height: 30px;
  }

  .donation_box_tabs li{
    width: 200px;
    border-radius: var(--radius)!important;
    margin-bottom: var(--padding_small);
  }
  .welcome_popup img{
    width:150px;
  }
  /* SIGN */
  .sign_container ul li{    
    width: 100%;
    max-width: 200px;
    border-radius: var(--radius)!important;
  }
  .sign_container ul li:first-child{    
    margin-bottom: var(--padding_small);
  }

  /* MARKET */
  .shop_shader {
    height: 30px;
  }

  /* CART */
  .dashboard_box .cart_image{
    display: none;
  }
  .dashboard_box .cart_info{
    width: 60%;
  }
  .dashboard_box .cart_buttons{
    width: 40%;
  }

  /* ORDER */
  .payment_cart_container, .payment_icons_container{
    display: none;
  }

  /* WITHDRAW */
  .instructions_container{
    text-align: right;
  }
  .instructions_container svg{
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
  }

  .baccarat_subtitles{
    max-width: 90px;
  }
}
@media (max-width:360px) {
  h1 {
		font-size: 24px;
		line-height: 26px;
	}
	h2 {
		font-size: 18px;
		line-height: 20px;
	}
	h3 {
		font-size: 16px;
		line-height: 18px;
	}
	h4 {
		font-size: 14px;
		line-height: 16px;
	}	
	h6 {
		font-size: 10px;
		line-height: 12px;
	}
  #splash_screen .mid-diamond-left{
    width: 30px;
    height: 30px;
    left: -40px;
    top: 85px;
  }
  #splash_screen .mid-diamond-right{
    width: 30px;
    height: 30px;
    right: -40px;
    top: 85px;
  }
  .question_subcategory h2 {
		font-size: 22px;
		line-height: 22px;
	}  
}
@media (max-width:340px) {
  .dashboard_box .cart_info, .dashboard_box .cart_buttons{
    width: 50%;
  }
  .warningGambling_box {
    width: 100%;
  }
}

@media (max-width: 960px) and (orientation: landscape){}
@media (max-width: 768px) and (orientation: landscape){
  /* SALON */
  .header{
    margin-bottom: var(--padding_small);
  }
  #header_salon #logo_icon {
    width: 20px;
  }
  #header_salon h1{
    font-size: 30px;
    line-height: 30px;
  }
  .salon_button, .panel_button {
    height: 40px;
  }
  .salon_button span, .panel_button span{
    display: none;
  }

  /* GAMES */
  .dice_container{
    margin-bottom: var(--padding);
  }
  .carousel_race_container{
    padding-left: 50px;
  }

  .game_bets_box {
    max-height: 200px;
  }

  .button_action_group{
    position: fixed;
    z-index: 9999;
    top: 20px;
    left: 0px;
    width: 70px;
  }
  .button_action_group .round{
    margin: var(--padding_small)!important;
  }
  .button_action_group.keno_prize_container{
    right: 50px;
    left: auto;
  } 
  .button_action_group.baccarat_table_buttons_container{
    position: static;
    width: 100%;
  }
  .keno_prize_container button{
    height: auto;
    line-height: 12px;
    padding: var(--padding);
  }

  /* CHATBOT */
  .chatbot_textarea_container{
    height: 160px;
  }
  .chatbot_textarea .message_box{
    font-size: 12px;
    line-height: 14px;
  }

  /* FOOTER */
  .footer_container.up .footer_body {
    height: 220px;
  }
}

@media (max-height: 400px){
	#roulette_canvas, .game_start{
    display: inline-block;
    vertical-align: middle;
  }
  #roulette_canvas{
    margin-right: var(--padding_small);
  }
  #roulette .game_start{
    max-width: 70px;
  }
  #user_title h3{
    font-size: 20px;
  }
  #user_tags, #user_list {
    margin-top: var(--padding_small);
  }
  #user_list li{
    padding: 8px var(--padding);
  }
}
@media (max-height: 500px){
  /* KENO */
  .keno_board{
    width: 100%;
    overflow: auto;
    height: 280px;
    padding: var(--padding_small);
  }

	.header_container.game{
    display: none;
  }
  .gameboard{
    padding: 0px var(--padding_small);
  }
  #craps_board{
    height: 150px;
  }
  .dice_container, .craps_container .button_action_group{
    display: inline-block;
    vertical-align: middle;    
  }
  .how_to_play_titles{
    max-height: 200px;
  }
}

::-webkit-scrollbar{
	width: 10px;
	height:5px;
	border-radius: var(--radius);
}
::-webkit-scrollbar-track {
	border-radius: var(--radius);
	background:var(--color);
	border:solid 4px var(--bg)
}
::-webkit-scrollbar-thumb{
	border-radius: var(--radius);
	background-color: var(--color);
	border:solid 1px var(--bg);
  cursor: pointer;
}

.error ::-webkit-scrollbar-track {
	background:var(--darkred);
}
.error ::-webkit-scrollbar-thumb{
	background-color: var(--darkred);
}
.success ::-webkit-scrollbar-track {
	background:var(--darkgreen);
}
.success ::-webkit-scrollbar-thumb{
	background-color: var(--darkgreen);
}

.under_construction_icon{
	width: 150px;
}

.slick-slide {
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

/* Active state when dragging */
.slick-slide:active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

.adsbygoogle_container{
  max-height: calc(35vh)!important;
  overflow: auto;
  display: none; /* this will change */
}